import React, {useCallback, useEffect, useState}  from "react";
import ModalUpgradeCampaing from "pctComponents/modals/ModalUpgradeCampaign";

import NiceModal from "@ebay/nice-modal-react";
import { connect } from "react-redux";
import { License } from "reducers/license";

type Props = {
  data: Block,
  className: string,
  planUpgrade?: License
}
const Campaing = ({data, planUpgrade}: Props) => {
  const [closeModal, setCloseModal] = useState<boolean>(true)
  const [refInterval, setRefInterval] = useState<NodeJS.Timeout>();
  const valueInMilliseconds = (valueInMinutes: number): number => {
   return  valueInMinutes * 60 * 1000
  }
  const isSleeping = () => {    
    const _localStorage = localStorage.getItem("campaing")
    const localStorageCampaing = _localStorage ? JSON.parse(_localStorage) : null;
    if(localStorageCampaing){
      const date = new Date(localStorageCampaing);
      return date.getTime() > new Date().getTime()
    }
    return false
  }

 

  const initInterval = useCallback(() => {
    const { frequency }  = data.parameters 
    const frequencyInMilliseconds =  valueInMilliseconds(parseInt(frequency || '0'))
    const intervalId = setInterval(() => {
      if(!isSleeping()){
        showModalCampaingUpdgrade()
      }
    }, frequencyInMilliseconds)

    setRefInterval(intervalId)
  }, [data.parameters])


  const showModalCampaingUpdgrade = useCallback(() => {  
    if (!planUpgrade?.isUpgradeButtonActive) {
      clearInterval(refInterval)
      return
    }
    if (!planUpgrade.userIsAdmin && planUpgrade.hasRequestedUpgrade) {
      clearInterval(refInterval)
      return
    } 
    if(!closeModal){
      return
    }
    NiceModal.show(ModalUpgradeCampaing, {
      obj: {
        closedModal: (e) => {
          setCloseModal(true)
        },
        configModal: {...data.parameters},
        idBlock : data.idBlock,
        idNode:  data.idNode,
        sleepTime: valueInMilliseconds(parseInt(data.parameters.sleepTime || '0')) 
      },
    });
  }, [planUpgrade]);

  const hasDateAvailabilityPeriod = useCallback((): boolean => {
    const { availabilityPeriod } = data.parameters    
    const dateArray = availabilityPeriod?.split('|')
    if(!dateArray?.length){
      return false
    }
    const dateInit =   new Date(dateArray[0])
    const dateEnd =   new Date(dateArray[1])
    const dateNow = new Date()
    return dateNow  >= dateInit && dateNow <= dateEnd
  }, [data.parameters ])


  
  const rulesShowModal = useCallback(() => {
    if(!hasDateAvailabilityPeriod()){
      return
    }
    !refInterval &&  initInterval();
    if(isSleeping()){
      
      return;
    }
    showModalCampaingUpdgrade();
   

  }, [showModalCampaingUpdgrade])
  useEffect(() => { rulesShowModal()}, [rulesShowModal])
  return <></>
}


const mapStateToProps = (store) => ({
  planUpgrade: store.planUpgrade
});
export default connect(mapStateToProps)(Campaing);