import './style.scss';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { getProfileInfo } from 'services/utils';
import { updateConfigHeaderFooter } from '../../../actions';
import { Preloader } from '../../atoms';
import { api, getToken } from '../../../services/api';
import { EvaluationKnowledge, EvaluationsReaction } from 'pctComponents/organisms';
import { ContextEvaluationKnowledge } from '../../../pctContexts/contextEvaluationKnowledge';
import useMediaQuery from 'pctHooks/useMediaQuery';

type evaluationsType = {
    idEnrollment?: string;
    idEvaluation?: string;
    idQuestionnaire?: string;
};

const Evaluations = (props) => {
    const isMobile = useMediaQuery('md');
    const [fetchData, setFetchData] = useState<any>();
    const [errorFetchData, setErrorFetchData] = useState(false);
    const userProfile = getProfileInfo();
    const { updateConfigHeaderFooter } = props;
    const { idEnrollment, idEvaluation, idQuestionnaire } = useParams<evaluationsType>();

    useEffect(() => {
        if(isMobile){
          document.body.classList.add('bodyOverflowHidden');
        }
        else{
          document.body.classList.remove('bodyOverflowHidden');
        }

        return () => {
            document.body.classList.remove('bodyOverflowHidden');
        };
    }, [isMobile]);

    const retrieveEvaluationData = async () => {
        try {
            const header = {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            };

            const url = `learning/students/${userProfile.idUser}/enrollments/${idEnrollment}/evaluations/${idEvaluation}/questionnaires/${idQuestionnaire}`;

            const { data } = await api.get(url, header);
            setFetchData(data);
        } catch (error) {
            setErrorFetchData(true);
        }
    };

    useEffect(() => {
        retrieveEvaluationData();
    }, [idEnrollment, idEvaluation, idQuestionnaire]);

    const callUpdateConfigHeaderFooter = () => {
        ///Atualizar Campaigins
        updateConfigHeaderFooter({
            footerStyle: 'hide',
            prevNavStyle: 'closeShow',
            contentNavigation: fetchData,
            backCloseText: 'FECHAR AVALIAÇÃO',
        });
        ///Atualizar Campaigins
    };

    const finishEvent = () => {
        retrieveEvaluationData();
    };

    const currentEvaluationType = () => {
        const { evaluation } = fetchData;
        switch (evaluation.evaluationType) {
            case 'REACTION':
                // AVALIAÇÃO DE REAÇÃO.
                return (
                    <div className={`pctPage pctEvaluation`}>
                        <EvaluationsReaction
                            fetchData={fetchData}
                            idEnrollment={idEnrollment}
                            idEvaluation={idEvaluation}
                            idQuestionnaire={idQuestionnaire}
                            finishEvent={finishEvent}
                        />
                    </div>
                );

                break;

            case 'KNOWLEDGE':
                // AVALIAÇÃO DE CONHECIMENTO.
                return (
                    <ContextEvaluationKnowledge>
                        <div className={`pctPage pctEvaluation`}>
                            <EvaluationKnowledge
                                fetchData={fetchData}
                                idEnrollment={idEnrollment}
                                idEvaluation={idEvaluation}
                                idQuestionnaire={idQuestionnaire}
                                idNodeEnrollment={fetchData.idNodeEnrollment}
                                finishEvent={finishEvent}
                            />
                        </div>
                    </ContextEvaluationKnowledge>
                );

                break;

            default:
                return <p>TIPO DE AVALIAÇÃO NÃO ENCONTRADA.</p>;

                break;
        }
    };

    if (errorFetchData) return <p>There is an error.</p>;
    if (!fetchData) return <Preloader />;

    callUpdateConfigHeaderFooter();

    return currentEvaluationType();
};

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Evaluations);
