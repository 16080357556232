import "./style.scss";
import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import SVG from "react-inlinesvg";

import useCards from "pctHooks/useCards";
import useSrcSetVerify from "pctHooks/useSrcSetVerify";

import {
  Label,
  Text,
  Image,
  Star,
  ProgressBar,
  Duration,
  Visualization,
  Mandatory,
  Sequential,
  Registered,
  ButtonCertificate,
  Trophy,
  Like,
  ViewSyllabus,
} from "../../atoms";
import ShareButton from "pctComponents/organisms/ShareButton";
import { getProfileInfo } from "services/utils";

type idType = {
  id?: string;
};

type Props = {
  className?: string;
  css?: object;
  collection: BlockElement | any;
  dataParent?: any;
  format: string;
  effects: string;
};

const CardStandard = ({
  collection,
  className = "",
  dataParent,
  css = {},
  format = "portrait",
  effects = "",
}: Props) => {
  const { id } = useParams<idType>();

  const setProgressStateRef = useRef(null as any);
  const progressInfoRef = useRef(null as any);
  const user = getProfileInfo();

  //
  const {
    image,
    concluidedText,
    verifyCompletedStatus,
    currentLabel,
    isEnrollment,
    canEnrollment,
    noTeamAvaliable,
    initialProgressState,
    evaluationEnrollment,
    statusRegistered,
    prerequisitesMet,
    routerLink,
    handleCardMouseEvent,
    handleCertificateMouseEvent,
    hasCertificate,
    currentBreadText,
    statusElement,
    hasEnrollment,
  } = useCards({
    collection,
    id,
    dataParent,
    setProgressStateRef,
    progressInfoRef,
  });

  const [progressInfo, setProgressState] = useState(initialProgressState());

  setProgressStateRef.current = setProgressState;
  progressInfoRef.current = progressInfo;

  const stsEnrollment =
    hasEnrollment() && collection.data?.nodeType !== "ORGANIZATION";

  const imgBase =
    statusElement() === "ARCHIVED" && image().image ? image().image : image();
  const srcSetVerify = useSrcSetVerify(imgBase, format);

  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;

  const isYouTubeLink = (link) => {
    return youtubeRegex.test(link);
  };

  return (
    <div
      className={`pctMolecule pctCard pctCardStandard ${
        prerequisitesMet() === false ||
        statusRegistered() === "inactive" ||
        statusElement() === "ARCHIVED"
          ? "pctCardBlockLock"
          : ""
      } `}
      style={css}
      onClick={(e) => handleCardMouseEvent(e)}
    >
      <Registered status={statusRegistered()} statusElement={statusElement()} />

      {/* {(star || award ) && <div className='gamifyData'>
                { <Star text={star} />}
                { <Award text={award} />}
            </div>} */}

      {collection.data.parentType !== "CATALOG" &&
        statusElement() !== "ARCHIVED" && (
          <div className="containerMandatoryLock containerMandatoryLock2">
            { isYouTubeLink(collection.data.contentLocation) && Boolean(collection.data.shareable)  ?
            <ShareButton className="ShareButtonCardStandard" callEvent={(e)=>{console.log(collection.data); e.stopPropagation()}} link={collection.data.contentLocation} idContent={collection.idElement} idUser={user.idUser} />
            : '' }
            <Mandatory
              status={collection.data.mandatory}
              className="mandatoryCardStandard"
            />
            <Sequential
              status={
                prerequisitesMet() === true && statusRegistered() != "inactive"
                  ? true
                  : false
              }
            />
          </div>
        )}

      <div className="containerData" data-aspectratio={format}>
        <Like
          direction="invert"
          collection={collection}
          statusRegistered={statusRegistered()}
        />

        {collection.data.image &&
          collection.elementType !== "EVALUATION" &&
          statusElement() !== "ARCHIVED" && (
            <Image
              path={image()}
              placeholder={true}
              pathSet={srcSetVerify}
              cardEffect={effects === "shadow"}
            />
          )}

        {!collection.data.image &&
          collection.elementType !== "EVALUATION" &&
          statusElement() !== "ARCHIVED" && (
            <div className="thumb">{image() && <SVG src={image()} />}</div>
          )}

        {/* Verificacao do tipo EVALUATION com imagem */}
        {image &&
          collection.elementType === "EVALUATION" &&
          collection.data.image && <Image path={image()} placeholder={true} />}

        {/* Verificacao do tipo EVALUATION sem imagem */}
        {collection.elementType === "EVALUATION" && !collection.data.image && (
          <div className="thumb">{image() && <SVG src={image()} />}</div>
        )}

        {statusElement() === "ARCHIVED" && (
          <div className="thumb">
            {
              <>
                {image().svg && <SVG src={image().svg} />}

                {!collection.data.image && (
                  <div className="thumb">
                    {image() && <SVG src={image().image} />}
                  </div>
                )}

                {collection.data.image && (
                  <Image
                    path={image().image}
                    placeholder={true}
                    pathSet={srcSetVerify}
                    cardEffect={effects === "shadow"}
                  />
                )}
              </>
            }
          </div>
        )}

        <div className={`progressConclusionConteiner`}>
          {/* {(collection.data.mandatory === 1 ||
            collection.data.enrollment && progressInfo > 0) && (
            <ProgressBar percentage={progressInfo} />
          )} */}

          {stsEnrollment && (
            <ProgressBar percentage={progressInfo} status={statusElement()} />
          )}

          {statusElement() !== "ARCHIVED" && (
            <div className="conclusionConteiner">
              {stsEnrollment && (
                <Text
                  component="small"
                  className="dis"
                  text={concluidedText()}
                />
              )}

              {hasCertificate() && (
                <ButtonCertificate
                  verifyCompletedStatus={verifyCompletedStatus()}
                  onClick={handleCertificateMouseEvent}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* {collection.data.mandatory == 1 && (
        <Text component="small" className="dis " text={concluidedText()} />
      )} */}

      {
        <Text
          text={currentBreadText()}
          component={"small"}
          className="breadCard"
        />
      }
      <Text
        component="p"
        text={collection.data?.title}
        title={true}
        className="titleCard"
      />

      <div className="labelData">
        <div className="labelDataItem leftInfo">
          <Label text={currentLabel()} className="label" />
          <div className="blockDataItens">
            <div className="bDataItem">
              <Duration
                text={collection.data?.workload}
                className="RightInfo"
                timeFormat={"seconds"}
              />
            </div>
            <div className="bDataItem">
              <ViewSyllabus collection={collection} />
            </div>
          </div>
        </div>

        {/* <Trophy idElement={collection.idElement} /> */}

        {collection.data.views > 0 && (
          <Visualization text={collection.data.views} />
        )}
      </div>
    </div>
  );
};

export default CardStandard;
