import NiceModal from '@ebay/nice-modal-react'; 
import moment from 'moment';
import ModalUpgrade from 'pctComponents/modals/ModalUpgrade';
import { OriginNavigation, Profile } from 'pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model';

import { getNumberRequest, getTrialPlans } from 'services/api';
import { compareTwoArrays } from 'services/utils';

type PropsOpenModal = {
    numberRequest: number;
    userIsAdmin: boolean;
    hasRequestedUpgrade?: boolean;
    isUpgradeButtonActive?: boolean;
    originNavigation: OriginNavigation;
};

type ActivePlan = {
    name?: string;
    idPlan: number;
    idCompanyApp: number;
    finishDate: string;
    trial?: boolean;
};

const usePlan = () => {
    const getData = async () => {
        const _menuData = localStorage.getItem('menu-data');
        const menuDataInfo = _menuData ? JSON.parse(_menuData) : null;
        const trialPlans = await getTrialPlans(true);

        const appsUserAccess = menuDataInfo['loginInfo']['appsUserAccess'];
        const trialPlansData = trialPlans.data;
        const activePlan = menuDataInfo['activePlan'] || {};

        const { idPlan, trial }: ActivePlan = activePlan;

        const comparePlans = compareTwoArrays(appsUserAccess, trialPlansData, 'idApp', 'idAppAvailable');
        const _idPlan = activePlan ? idPlan : comparePlans[0]?.plans[0]?.idPlan;

        const _getNumberRequest = idPlan ? await getNumberRequest(_idPlan) : null;
        const { alreadyRequested = false, requestsCount = 0, likeCount = 0 } = _getNumberRequest?.data || {};
        const data = {
            isUpgradeButtonActive: !!trial || comparePlans.length > 0,
            userIsAdmin: [1, 2].includes(menuDataInfo?.loginInfo?.admin),
            hasRequestedUpgrade: alreadyRequested,
            numberRequest:  !!trial ? likeCount: requestsCount,
            plansContainer: comparePlans,
        };

        return data;
    };

    const getAvailablePlans = async (withDetails = true) => {
        const { data } = await getTrialPlans(withDetails);
        return data;
    };

    const triggerCallBackInterval = (activePlanData, callBack = () => {}, timeout = 5000) => {
        let planModalValue = -1;

        if (activePlanData.deadline === 1) {
            planModalValue = 1;
        } else if (activePlanData.deadline >= 2 && activePlanData.deadline <= 5) {
            planModalValue = 2;
        } else if (activePlanData.deadline >= 6 && activePlanData.deadline <= 10) {
            planModalValue = 3;
        }

        if (planModalValue !== -1 && sessionStorage.getItem('planModal') !== String(planModalValue)) {
            sessionStorage.setItem('planModal', String(planModalValue));

            if (!localStorage.getItem('planModal') || localStorage.getItem('planModal') !== String(planModalValue)) {
                localStorage.setItem('planModal', String(planModalValue));
                setTimeout(() => {
                    callBack();
                }, timeout);
            }
        }
    };

    const getActivePlanData = () => {
        const _menuData = localStorage.getItem('menu-data');
        const menuDataInfo = _menuData ? JSON.parse(_menuData) : null;
        const loginInfo = menuDataInfo['loginInfo'];
        const activePlan = menuDataInfo['activePlan'];
        if (!activePlan) {
            return;
        }

        const { name, idPlan, idCompanyApp, finishDate, trial }: ActivePlan = activePlan;

        const startTime = moment().startOf('day');
        const finishTime = moment(finishDate).startOf('day');

        const deadline = finishTime.diff(startTime, 'days');

        return {
            name,
            idPlan,
            idCompanyApp,
            finishDate,
            userIsAdmin: [1, 2].includes(loginInfo?.admin),
            isButtonActive: !!activePlan,
            deadline,
            trial,
        };
    };

    const openModalUpgrade = ({ userIsAdmin, hasRequestedUpgrade = false, numberRequest, originNavigation }: PropsOpenModal) => {
        NiceModal.show(ModalUpgrade, {
            obj: {
                hasRequestedUpgrade: hasRequestedUpgrade,
                numberOfClient: numberRequest,
                originNavigation: originNavigation,
                profile: userIsAdmin ? Profile.admin : Profile.client,
            },
        });
    };

    return { openModalUpgrade, getData, getActivePlanData, getAvailablePlans, triggerCallBackInterval };
};

export default usePlan;
