import './style.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { CarrousselView } from 'pctComponents/molecules';
import { Text, Button } from 'pctComponents/atoms';

import CardUpgrade from '../CardUpgrade';
import parse from 'html-react-parser';
import { DialogModel, PlaneProps, Trial, ViewScreen } from '../../@model/ManagerTrial.model';

import { useManagerTrial } from '../../context/contextManagerTrial';

import { textPlansStatus } from '../../utils/TextPlansStatus';
import { dialogContainerModel } from '../../utils/DialogContainerModel';
import { useModal } from '@ebay/nice-modal-react';
import PartyPopper from 'pctComponents/svg/PartyPopperSVG';

const SelectedPlanUpgrade = ({}) => {
    const [otherPlans, setOtherPlans] = useState<PlaneProps[]>([]);
    const modal = useModal();

    useEffect(() => {
        getOtherPlans();
    }, []);

    const { trialStatusPlan, currentPlan, setViewScreenPlan, setDialogPlan, listPlans } = useManagerTrial();
    const { name, deadline, planUpgrade } = currentPlan as PlaneProps;

    const planText = {
        screen: 'SelectedPlanUpgrade',
        trialStatusPlan: trialStatusPlan,
        deadline,
        plan: `${name}`,
        requestsInfo: planUpgrade?.numberRequest,
    };

    const getOtherPlans = () => {
        const areOtherPlans = (listPlans || []).filter((plan) => plan.idPlan !== currentPlan?.idPlan);
        setOtherPlans(areOtherPlans as PlaneProps[]);
    };

    const buyPlan = async () => {
        setViewScreenPlan(ViewScreen.calculatorPlan);

        // setViewScreenPlan(ViewScreen.dialog);
        // setDialogPlan(
        //   dialogContainerModel({
        //     type: DialogModel.ConfirmPlan,
        //     plan: name,
        //     buttonConfirm: {
        //       callEvent: async () => {
        //         const _menuData = localStorage.getItem('menu-data');
        //         const {token} = _menuData ? JSON.parse(_menuData) : null;

        //         const _appDetails = localStorage.getItem('appDetails');
        //         const {adminBucket} = _appDetails ? JSON.parse(_appDetails) : null;

        //         window.open(`https://${adminBucket}?token=${token}#/login`);
        //         modal.remove();
        //       },
        //     },
        //     buttonCancel: {
        //       callEvent: async () => {
        //         modal.remove();
        //       },
        //     }
        //   })
        // );
    };

    return (
        <div className="pctSelectedPlanUpgrade">
            <div className={`pctSelectedPlaItemCurrent`}>
                {currentPlan && <CardUpgrade key={`card-upgrade-${currentPlan.idRef}`} showDetail={true} elem={currentPlan} />}
            </div>
            <div className="pctSelectedPlaItemInfo">
                {planUpgrade && planUpgrade.numberRequest > 0 && (
                    <div className={`rowItem padding`}>
                        <div className="circle">
                            <span className="icone">{parse(`${PartyPopper()}`)}</span>
                        </div>
                        <Text
                            component={'p'}
                            text={textPlansStatus({
                                ...planText,
                                element: 'requests-info',
                            })}
                        />
                    </div>
                )}

                <Text
                    component={'h1'}
                    text={textPlansStatus({
                        ...planText,
                        element: 'h1',
                    })}
                />
                <Text
                    component={'p'}
                    text={textPlansStatus({
                        ...planText,
                        element: 'p',
                    })}
                />
                <div className="buttonsWrapperSelected">
                    {otherPlans.length > 0 && (
                        <Button
                            callEvent={() => {
                                setViewScreenPlan(ViewScreen.upgrade);
                            }}
                            className={`button01`}
                            text={`Ver planos`}
                        />
                    )}
                    <Button callEvent={buyPlan} className={`button06`} text={`Contratar agora`} />
                </div>
            </div>
        </div>
    );
};

export default SelectedPlanUpgrade;
