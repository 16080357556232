import React from 'react';
import ReactDOM from 'react-dom';
import './themes/import.scss';
// import 'bootstrap/dist/css/bootstrap.min.css'; // [PCT] coloquei dentro index.scss 
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { Store } from './store';
import { ClearCacheProvider  } from 'react-clear-cache';

ReactDOM.render(
  <Provider store={Store}>
    <ClearCacheProvider duration={5000}>
      <App />
    </ClearCacheProvider>
  </Provider>,
  document.getElementById('root') 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
