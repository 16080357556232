import {
  UPDATE_VALUE,
  UPDATE_HEADER,
  UPDATE_CONTENT_TYPE,
  UPDATE_POINTS,
  UPDATE_SCORE_POINTS,
  CONFIG_HEADER_FOOTER,
  UPDATE_COURSES_IN_PROGRESS,
  CONFIG_CONTENTS_MAP,
  CONFIG_CAMPAIGN,
  CONFIG_HAS_ENROLLMENT_CAMPAIGN,
  UPDATE_LICENSE
} from "./actionTypes";

export const updateFooterStatus = (value) => ({
  type: UPDATE_VALUE,
  footerStatus: value,
});

export const updateHeaderStatus = (value) => ({
  type: UPDATE_HEADER,
  headerStatus: value,
});

export const updateContentType = (value) => ({
  type: UPDATE_CONTENT_TYPE,
  currentContentType: value,
});

export const updateScorePoints = (value) => {
  return {
    type: UPDATE_SCORE_POINTS,
    scorePoints: value,
  };
};
export const updatePoints = (value) => {
  return {
    type: UPDATE_POINTS,
    points: value,
  };
};
// PCT
export const updateCoursesInProgress = (value) => {
  return {
    type: UPDATE_COURSES_IN_PROGRESS,
    courses_in_progress: value,
  };
};

// PCT
export const updateConfigHeaderFooter = (value) => ({
  type: CONFIG_HEADER_FOOTER,
  configHeaderFooter: value,
});

// PCT
export const updateConfigContentsMap = (value) => ({
  type: CONFIG_CONTENTS_MAP,
  configContentsMap: value,
});

export const updateConfigCampaign = (value) => ({
  type: CONFIG_CAMPAIGN,
  configCampaign: value,
});

export const updateHasEnrollmentCampaign = (value) => ({
  type: CONFIG_HAS_ENROLLMENT_CAMPAIGN,
  configHasEnrollmentCampaign: value,
});

export const updatePlan = value => ({
    type: UPDATE_LICENSE,
    values: value
});

