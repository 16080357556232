import React, { useState, useEffect, Fragment, ChangeEvent } from "react";

import "./style.scss";

import ModalDialog from "../../modals/ModalDialog";

import { Text, Button, Preloader, Switch } from "../../atoms";

import { getProfileInfo } from "services/utils";

import { Tooltip } from "@material-ui/core";

import { postNotificationsConfigurationsUsers } from "services/api";

export enum typeModel {
  ENROLLMENT_CREATED = "ENROLLMENT_CREATED",
  ENROLLMENT_COMPLETED = "ENROLLMENT_COMPLETED",
  USER_CREATED = "USER_CREATED",
  PASSWORD_REQUESTED = "PASSWORD_REQUESTED",
}

export enum Section {
  LEARNING = "LEARNING",
  SYSTEM = "SYSTEM",
}

export enum Options {
  DEACTIVATED = "DEACTIVATED",
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL",
}

export type NotificationConfig = {
  active: number | boolean;
  description: string;
  idNotification: number;
  idNotificationUser: number;
  section: Section;
  status: Options | string;
  type: typeModel;
  update?: boolean;
};

const ModalNotification = ({
  notificationModal,
  setNotificationModal,
  notifications,
}) => {
  const [loading, setLoading] = useState(false);

  const [notificationsInfo, setNotificationsInfo] = useState<
    NotificationConfig[] | null
  >(null);

  useEffect(() => {
    if (notifications) {
      setNotificationsInfo(notifications);
    }
  }, [notifications]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    item: NotificationConfig
  ) => {
    const notificationsMap = notificationsInfo?.map((it) => {
      if (it === item) {
        it.active = Number(event.target.checked);
        it.update = true;
      }
      return it;
    });
    if (notificationsMap) setNotificationsInfo(notificationsMap);
  };

  const cancelNotification = () => {
    setNotificationModal(false);
  };

  const saveNotification = () => {
    const idUser = getProfileInfo().idUser;
    const notificationUpdate = notificationsInfo?.filter((it) => it.update);

    if (notificationUpdate && notificationUpdate.length > 0) {
      const notificationUpdateMap = notificationUpdate.map((it) => {
        const elem = {
          idNotification: it.idNotification,
          idNotificationUser: it.idNotificationUser,
          type: it.type,
          active: it.active,
        };

        return elem;
      });

      setLoading(true);
      postNotificationsConfigurationsUsers(notificationUpdateMap, idUser).then(
        (res) => {
          setLoading(false);
          setNotificationModal(false);
        }
      );
    } else {
      setNotificationModal(false);
    }
  };

  const validateCheck = (item) => {
    const { status, active } = item;

    if (status === Options.OPTIONAL) {
      return !!active;
    } else {
      return status === Options.MANDATORY ? true : false;
    }
  };

  const NotificationManager = () => {
    return (
      <>
        {loading && <Preloader />}
        <div className="notification-container">
          <Text
            component={"h4"}
            text={
              "Marque as opções desejadas de acordo com os seus interesses."
            }
          />
          <div className="box-notification">
            <Text component={"h4"} text={"Atividades de treinamentos"} />
            <Text
              component={"p"}
              text={
                "Enviar notificações sobre as atividades de treinamento quando:"
              }
            />
            <div className="list-notification">
              {notificationsInfo &&
                notificationsInfo
                  .filter((it) => it.section === Section.LEARNING)
                  .map((item) => {
                    return (
                      <Fragment key={item.idNotification}>
                        <Tooltip
                          title="Essa opção não pode ser editada por ser uma definição geral do sistema"
                          disableHoverListener={
                            item.status !== Options.OPTIONAL ? false : true
                          }
                        >
                          <div className="switchContainer">
                            <Switch
                              disabled={
                                item.status !== Options.OPTIONAL ? true : false
                              }
                              checked={validateCheck(item)}
                              onChange={(e) => handleChange(e, item)}
                              name={item.type}
                              label={item.description}
                            />
                          </div>
                        </Tooltip>
                      </Fragment>
                    );
                  })}
            </div>
            <hr />
            <Text component={"h4"} text={"Atividades gerais na plataforma"} />
            <Text
              component={"p"}
              text={"Enviar notificações sobre as atividades gerais quando:"}
            />
            <div className="list-notification">
              {notificationsInfo &&
                notificationsInfo
                  .filter((it) => it.section === Section.SYSTEM)
                  .map((item) => {
                    return (
                      <Fragment key={item.idNotification}>
                        <Tooltip
                          title="Essa opção não pode ser editada por ser uma definição geral do sistema"
                          disableHoverListener={
                            item.status !== Options.OPTIONAL ? false : true
                          }
                        >
                          <div className="switchContainer">
                            <Switch
                              disabled={
                                item.status !== Options.OPTIONAL ? true : false
                              }
                              checked={validateCheck(item)}
                              onChange={(e) => handleChange(e, item)}
                              name={item.type}
                              label={item.description}
                            />
                          </div>
                        </Tooltip>
                      </Fragment>
                    );
                  })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <ModalDialog
      className="darkDilog modalNotication"
      title={"Gerenciar Notificações"}
      htmlBody={<NotificationManager />}
      btn1Info={{ event: cancelNotification, text: "Cancelar" }}
      btn2Info={{
        event: saveNotification,
        text: "Salvar",
        className: "active",
      }}
      show={notificationModal}
      setShow={setNotificationModal}
    />
  );
};

export default ModalNotification;
