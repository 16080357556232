import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import "./style.scss";
import { ModalUpgradeProps } from "pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model";
import { ManagerTrial } from "pctComponents/organisms";

const ModalUpgrade = NiceModal.create(({ obj }: ModalUpgradeProps) => {
  const modal = useModal();

  return (
    <div className={`ModalUpgrade`}>
      <div className={`modal-body-pct`}>
        <>
          <ManagerTrial profile={obj.profile} modal={modal} />
        </>
      </div>
    </div>
  );
});

export default ModalUpgrade;
