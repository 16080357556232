import "./style.scss";
import React, { Component, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateConfigHeaderFooter } from "../../../actions";

import { Preloader } from "pctComponents/atoms";
import { getProfileInfo } from "services/utils";
import ErrorComponent from "pctComponents/organisms/ErrorComponent";
import AlertComponent from "pctComponents/organisms/AlertComponent";

import { useFetch } from "pctHooks/useFetch";
import { usePrintBlocks } from "pctHooks/usePrintBlocks";
import useCampaingns from "pctHooks/useCampaigns";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalAppList from "pctComponents/modals/ModalAppList";
import ModalAccessSupport from "pctComponents/modals/ModalSupport";

const Home = (props) => {
  const rootNode = getProfileInfo().idCompany;
  const { data, error } = useFetch({
    url: `/learning/nodes/${rootNode}`,
    page: "home",
  });

  const { showListCampaingns } = useCampaingns({});

  useEffect(() => {
    const { updateConfigHeaderFooter } = props;
    updateConfigHeaderFooter({ prevNavStyle: "hide" });
    window.scrollTo(0, 0);
    showListCampaingns();
    localStorage.removeItem("redirectToken");
    NiceModal.register('ModalAccessSupport', ModalAccessSupport);
    // NiceModal.show(ModalAppList, {
    //   obj: {
    //     // closedModal:(e) => {
    //     //
    //     // }
    //   },
    // });
  }, []);

  if (error) return <ErrorComponent error={error} />;
  if (!data) return <Preloader />;

  const getBlockTypeComponent = usePrintBlocks;
  let fetchData: any = data;

  return (
    <div className="pctPage pctPageBlock ptcHome">
      {fetchData.blocks.map((block: any, i: number) => {
        return getBlockTypeComponent(block, i);
      })}

      {fetchData.blocks.length == 0 && (
        <AlertComponent
          message={`A página principal não possuí blocos de conteúdos.`}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Home);
