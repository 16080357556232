import React, { ReactNode, useMemo, useState } from 'react';
import './style.scss';
import PartyPopper from 'pctComponents/svg/PartyPopperSVG';
import parse from 'html-react-parser';
import Button from 'pctComponents/atoms/Button';
import { updatePlan } from 'actions';
import { connect } from 'react-redux';
import NiceModal, { NiceModalHandler, useModal } from '@ebay/nice-modal-react';
import ModalLoadingInfo from 'pctComponents/modals/ModalLoadingInfo';
import SmileSvg from 'pctComponents/svg/SmileSVG';
import { planUpgradeProps } from '../../@model/ManagerTrial.model';
import usePlan from 'pctHooks/usePlan';
type Props = {
    modal?: NiceModalHandler;
    planUpgrade: planUpgradeProps;
};
const PctClientUpgrade = ({ modal, planUpgrade }: Props) => {
    const { hasRequestedUpgrade, numberRequest } = planUpgrade;
    NiceModal.register('ModalLoadingInfo', ModalLoadingInfo);
    const [isUpdateRequest, setIsUpdateRequest] = useState<boolean>(hasRequestedUpgrade);
    const { getActivePlanData } = usePlan();
    const closeModal = () => {
        modal?.remove();
    };

    const dataPlan = useMemo(() => {
        const data = getActivePlanData() || null;
        if (data) {
            return { active: !!data, deadline: data.deadline };
        }

        return { active: false, deadline: null };
    }, [getActivePlanData]);

    const UpgradeCongratulations = ({ svg, children }: { svg: string; children: ReactNode }) => (
        <div className={`columnItem padding ${!numberRequest && 'padding-numberzero'}`}>
            <div className="circle">
                <span className="icone">{parse(`${svg}`)}</span>
            </div>
            <h3>{children}</h3>
        </div>
    );

    const SubtitleCongratulation = ({ text }: { text: string }) => (
        <div className="subtitle">
            <p>{text}</p>
        </div>
    );

    const UpgradeUserCount = () => (
        <div className="upgradeUserCount">
            <p>
                <span>{numberRequest} usuários </span>
                da sua plataforma gostariam de ter a plataforma
            </p>
        </div>
    );

    const QuestionSatisfaction = () => (
        <div className="padding-top">
            <div className="title">
                <h3>Está gostando da Plataforma?</h3>
                <p>Já imaginou poder criar seus próprios treinamentos?</p>
            </div>
        </div>
    );
    const Footer = ({ children, className }: { children: ReactNode; className?: string }) => <div className={`footer ${className}`}>{children}</div>;

    const UpgradeRequestsMessage = () => (
        <div className={`rowItem padding`}>
            {!!numberRequest && (
                <>
                    <div className="circle">
                        <span className="icone">{parse(`${PartyPopper()}`)}</span>
                    </div>
                    <h4>
                        <span>{numberRequest} usuários </span> da sua plataforma gostaria de fazer o upgrade.
                    </h4>
                </>
            )}
        </div>
    );

    const Congratulation = () => (
        <>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p> Obrigado por compartilhar!</p>
            </UpgradeCongratulations>
            <SubtitleCongratulation text={'O Administrador da sua plataforma acaba de ser notificado sobre o seu interesse.'} />
            {!!numberRequest && <UpgradeUserCount />}
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const ThanksForSharing = () => (
        <>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p> Obrigado por compartilhar!</p>
                <p className="subline">
                    {' '}
                    Ainda <span>restam {dataPlan.deadline} dias</span> para continuar aproveitado
                </p>
            </UpgradeCongratulations>
            <SubtitleCongratulation text={'O Administrador da sua plataforma acaba de ser notificado sobre a sua opinião da plataforma.'} />
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const FeedBackThanksForSharing = () => (
        <>
            <UpgradeCongratulations svg={PartyPopper()}>
                <p className="subline">
                    {' '}
                    Sua opinião <span>já foi compartilhada</span>
                </p>
                <p className="subline">Agora é com seu administrador!</p>
                <p className="subline">
                    Ainda <span>restam {dataPlan.deadline} para continuar aproveitado</span>
                </p>
            </UpgradeCongratulations>
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    const RequestAlreadyMade = () => (
        <>
            <UpgradeCongratulations svg={SmileSvg()}>
                <p>Solicitação já realizada</p>
            </UpgradeCongratulations>
            <SubtitleCongratulation text={'Agora é com o seu administrador!'} />
            {!!numberRequest && <UpgradeUserCount />}
            <Footer>
                <Button callEvent={() => closeModal()} className={'button03 button-default'} text={'OK, entendi'} />
            </Footer>
        </>
    );

    return (
        <div className="PctClientUpgrade">
            {dataPlan.active ? (
                <>{!isUpdateRequest ? <ThanksForSharing /> : <FeedBackThanksForSharing />}</>
            ) : (
                <>{!isUpdateRequest ? <Congratulation /> : <RequestAlreadyMade />}</>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updatePlan: (item) => dispatch(updatePlan(item)),
});

const mapStateToProps = (store) => ({
    planUpgrade: store.planUpgrade,
});
export default connect(mapStateToProps, mapDispatchToProps)(PctClientUpgrade);
