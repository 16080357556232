import React from "react";

import { Link } from "react-router-dom";
import { Text } from "pctComponents/atoms";

function HeaderLogo({
  searchOpen,
  logoStyle,
  homeScreenStatus,
  titleHeader,
  tituloStyle,
}) {
  const getAppIcon = () => {
    const menuData = JSON.parse(localStorage.getItem("menu-data"));
    return menuData.companyDesign.headerIcon;
  };
  return (
    <>
      <div
        className={`logoHeader ${
          searchOpen ? "logoHeaderMobileSearchOpen" : ""
        }`}
      >
        <div className={`logoHeaderContainer ${logoStyle}`}>
          {!homeScreenStatus ? (
            <Link to={"/"}>
              <img src={getAppIcon()} alt="logo" />
            </Link>
          ) : (
            <img src={getAppIcon()} alt="logo" />
          )}
        </div>

        {titleHeader && (
          <div className={`tituloHeaderContainer ${tituloStyle}`}>
            <Text component="h2" text={titleHeader} limit={40} tooltip={true} />
          </div>
        )}
      </div>
    </>
  );
}

export default HeaderLogo;
