import "./style.scss";

import React, { useEffect, useState, useRef } from "react";

import { withRouter, useParams, useHistory } from "react-router-dom";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { compose } from "redux";
import { connect } from "react-redux";

import { getProfileInfo } from "services/utils";

import { updateConfigHeaderFooter, updateCoursesInProgress } from "actions";

import { getLevelNodeEnrollment, createContentEnrollments } from "services/api";

import ModalLoadingInfo from "pctComponents/modals/ModalLoadingInfo";

import { Preloader, Text } from "pctComponents/atoms";

import ErrorComponent from "pctComponents/organisms/ErrorComponent";
import AlertComponent from "pctComponents/organisms/AlertComponent";

import { usePrintBlocks } from "pctHooks/usePrintBlocks";
import useBackControll from "pctHooks/useBackControll";
import useNodeEnrollment from "pctHooks/useNodeEnrollment";

type idType = {
  id: string;
  _nodeCode: string;
};

const Level = (props) => {
  const { id, _nodeCode } = useParams<idType>();
  const idUser = getProfileInfo().idUser;
  const { callNodeEnrollment } = useNodeEnrollment();
  const [fetchData, setFetchDataUpdate] = useState({} as LevelNode);
  const [errosData, setErrosData] = useState(null as any);
  const nodeEnrollmented = useRef(false);
  const canRender = useRef(false);
  const modalLoadingNodeEnrollment = useModal("ModalLoadingContentEnrollment");
  const history = useHistory();
  const { redirectFn, isfluidNavigationFn, cameFrom } = useBackControll({});


  const filterBlocksWithContents = (nodeCollection: LearningNode) => {
    let arrayElements = [] as Array<any>;

    const filterBlocks = nodeCollection.blocks.filter(
      (item) =>
        // item.blockType?.toString() != "MasterBanner" &&
        item.blockType?.toString() != "Catalog"
    );

    filterBlocks.map((block: any, i) => {
      arrayElements = arrayElements.concat(block.elements);

      if (
        block.blockType.toUpperCase() != "LIST" &&
        block.blockType.toUpperCase() != "GRID" &&
        block.blockType.toUpperCase() != "CARROUSEL"
      ) {
        arrayElements = arrayElements.concat(block);
      }
    });

    const filterContents = arrayElements.filter(
      (item) => item.elementType?.toString() === "CONTENT"
    );

    const filterOthers = arrayElements.filter(
      (item) => item.elementType?.toString() != "CONTENT"
      // item.elementType?.toString() != "EVALUATION"
    );

    const objBlocksTypes = { contents: filterContents, others: filterOthers };

    return objBlocksTypes;
  };

  NiceModal.register("ModalLoadingContentEnrollment", ModalLoadingInfo);

  useEffect(() => {
    const { updateConfigHeaderFooter } = props;

    setTimeout(() => {
      updateConfigHeaderFooter({
        nodeCollection: fetchData,
        backCloseText: "",
      });
    }, 500);
  }, [fetchData]);

  useEffect(() => {
    getLevelNodeEnrollment(idUser, id, _nodeCode)
      .then((response) => {
        if (response) {
          //CASO O NODE_ENROLLMENT AINDA NÃO EXISTA, VAMOS CRIAR NESTE MOMENTO.
          if (
            !response.data.node.nodeEnrollment.idNodeEnrollment &&
            nodeEnrollmented.current == false
          ) {
            callNodeEnrollment({
              collection: response,
              modalLoadingNodeEnrollment: modalLoadingNodeEnrollment,
              _parent: false,
              _onFinished: (_result) => {
                let tempObj = response.data;
                tempObj.node.nodeEnrollment.idNodeEnrollment =
                  _result.data.newId;

                tempObj.node.enrollment = {
                  idEnrollment:
                    tempObj.node.nodeEnrollment.enrollment.idEnrollment,
                };

                nodeEnrollmented.current = true;

                setTimeout(() => {
                  canRender.current = true;
                  setFetchDataUpdate(tempObj);
                  props.updateCoursesInProgress({ newNode: tempObj.node });
                }, 1000);
              },
            });
          } else {
            canRender.current = true;
            setFetchDataUpdate(response.data);
          }
        } else {
          throw new Error("Enrollment data not found.");
        }
      })
      .catch((error) => {
        // console.log(error);
        setErrosData(error);
      });
  }, []);

  const updateLevel = (value) => {
    getLevelNodeEnrollment(idUser, id, _nodeCode)
      .then((response) => {
        if (response) {
          canRender.current = true;
          setFetchDataUpdate(response.data);
        } else {
          throw new Error("Enrollment data not found on update.");
        }
      })
      .catch((error) => {
        // console.log(error);
        setErrosData(error);
      });
  };

  if (errosData) return <ErrorComponent error={errosData} />;
  if (!fetchData.node) return <Preloader />;

  const getBlockTypeComponent = usePrintBlocks;
  const { nodeEnrollment, nodeCode, originalNodeCode } = fetchData.node;

  const blockElementsSuportAttr = (block, idNodeEnrollment, idEnrollment) => {
    const blockEl = block.elements.map((it, p) => {
      it.idNodeEnrollment = idNodeEnrollment;
      it.idEnrollment = idEnrollment;
      it.setFetchDataUpdate = updateLevel;

      if (p != 0) {
        let befour = block.elements[p - 1];

        if (befour.data.contentEnrollment) {
          it.prerequisitesMet =
            befour.data.contentEnrollment.progress == 100 ? true : false;
        } else if (befour.data.nodeEnrollment) {
          it.prerequisitesMet =
            befour.data.nodeEnrollment.progress == 100 ? true : false;
        } else {
          it.prerequisitesMet = true;
        }
      } else {
        it.prerequisitesMet = true;
      }

      return it;
    });

    block.elements = blockEl;
    return block;
  };

  const routerLink = (collection, idNodeEnrollment, idEnrollment) => {
    let routerLinkTemp: string = "/";
    const idContentType = collection.data.idContentType;

    if (idContentType === 26) {
      //QUANDO O ID FOR ZERO SIGNIFICA QUE O USUÁRIO ESTÁ NA NAVEGAÇÃO FLUÍDA
      routerLinkTemp = `/lms/${id != "0" ? id : collection.idEnrollment}/${
        collection.idElement
      }/${idNodeEnrollment}/${id != "0" ? 0 : 1}`;
    } else {
      routerLinkTemp = `/contentNode/${
        collection.idElement
      }/${idNodeEnrollment}/${idEnrollment}/${
        getProfileInfo().idUser
      }/${"-"}/single`;

      const { updateConfigHeaderFooter } = props;

      updateConfigHeaderFooter({
        nodeCollection: { ...fetchData, levelSingle: true },
        backCloseText: "",
      });
    }

    if (
      !collection.data.contentEnrollment?.idContentEnrollment &&
      collection.data.idContentType !== 26
    ) {
      const objEnrollment = {
        idNodeEnrollment: idNodeEnrollment,
        idEnrollment: idEnrollment,
        idContentItem: collection.idElement,
        idUser: getProfileInfo().idUser,
        progress: 0,
        status: "IN_PROGRESS",
      };

      createContentEnrollments(objEnrollment)
        .then((response) => {
          isfluidNavigationFn(true);
          history.push(routerLinkTemp);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      isfluidNavigationFn(true);
      history.push(routerLinkTemp);
    }
  };

  const pctPage = (idNodeEnrollment, idEnrollment) => {
    //26 - ITEM DO TIPO SCORM
    //18 - ITEM DO TIPO ARQUIVO

    const itensTypes = filterBlocksWithContents(fetchData.node);
    const canRedirect =
      itensTypes.contents.length == 1 &&
      itensTypes.others.length == 0 &&
      cameFrom() !== "searchResult";

    // const canRedirect = itensTypes.contents.length == 1 &&  itensTypes.others.length == 0;

    if (canRedirect) {
      setTimeout(() => {
        redirectFn(history);
        routerLink(itensTypes.contents[0], idNodeEnrollment, idEnrollment);
      }, 1000);

      return <Preloader />;
    } else {
      if (itensTypes.contents.length == 1 && itensTypes.others.length == 0) {
        redirectFn(history);
      }

      return (
        <div className="pctPage pctPageBlock ptcLevel">
          {fetchData.node.blocks.map((block: any, i: number) => {
            const blockESupor = blockElementsSuportAttr(
              block,
              idNodeEnrollment,
              idEnrollment
            );
            return getBlockTypeComponent(blockESupor, i, fetchData);
          })}

          {fetchData.node.blocks.length == 0 && (
            <AlertComponent
              message={`A página ( ${originalNodeCode} -> ${nodeCode} ) não possuí blocos de conteúdos.`}
            />
          )}
        </div>
      );
    }
  };

  if (canRender.current) {
    // canRender.current = false;
    return pctPage(nodeEnrollment.idNodeEnrollment, +id);
  } else {
    return <></>;
  }
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
  updateCoursesInProgress: (item) => dispatch(updateCoursesInProgress(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Level);
