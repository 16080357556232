import "./styles.scss";
import React, { useEffect, useState } from "react";
import { Notification, Button } from "pctComponents/atoms";
import { getNumberRequest, postRequestTrial, postRequestUpgrade } from "services/api";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { MdRocketLaunch } from "react-icons/md";
import ModalLoadingInfo from "pctComponents/modals/ModalLoadingInfo";
import { OriginNavigation } from "pctComponents/organisms/ManagerTrial/@model/ManagerTrial.model";
import usePlan from "pctHooks/usePlan";

type Props = {
  text?: string;
  hide?: boolean;
  css?: object;
  className?: string;
  numberRequest: number;
  hideNotification: boolean;
  userIsAdmin: boolean;
  updatePlan?: any;
  hasRequestedUpgrade?: boolean;
  originNavigation: OriginNavigation;
  disableRuleIconModal?: boolean;
  plansContainer?: any
  callEvent?: React.MouseEventHandler<HTMLButtonElement> | null;
  isUpgradeButtonActive?: Boolean;
};

const PctUpgrade = ({
  text = "Conheça a LMS/LXP by Afferolab",
  hide = false,
  numberRequest = 1,
  hideNotification = false,
  userIsAdmin = false,
  hasRequestedUpgrade = false,
  originNavigation = OriginNavigation.header,
  disableRuleIconModal = false,
  updatePlan,
  isUpgradeButtonActive = true,
  plansContainer,
  callEvent,
  ...props
}: Props) => {
  const { openModalUpgrade } = usePlan();
  const modalLoading = useModal("ModalLoadingInfo");
  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  const openModal = () => {
    openModalUpgrade({
      hasRequestedUpgrade,
      userIsAdmin,
      numberRequest,
      originNavigation,
    });
  };
  const getNumberNotification = async () => {
    const idPlan =  plansContainer?.[0].plans[0].idPlan
    const _getNumberNotification = await getNumberRequest(idPlan);
    updatePlan({  hasRequestedUpgrade: true, numberRequest:_getNumberNotification.data.requestsCount});
  }


  const handleClick = (e) => {
    if (callEvent) {
      callEvent(e);
    }
    if (!userIsAdmin) {
      requestUpgrade();
      return;
    }
    else{
      getNumberNotification()
    }
    
    document.body.classList.add("bodyOverflowHidden");
    openModal();
  };

  const requestUpgrade = async () => {
    try {
      const idPlan =  plansContainer?.[0].plans[0].idPlan

      if(hasRequestedUpgrade){
        getNumberNotification()
        openModal();
        return
      }
      modalLoading.show({
        obj: {
          loadding: true,
          closeBtn: false,
        },
      });
      await postRequestTrial({idPlan:idPlan});
      openModal();
      getNumberNotification()

    } catch (e) {
      console.log(e);
    } finally {
      modalLoading.remove();
    }
  };
 
  return (
    <>
      {isUpgradeButtonActive && !hide && (
        <div className="pctUpgrade">
          {!hideNotification && !!numberRequest && (
            <Notification
              text={`${numberRequest}`}
              hasBell={false}
              className="notification"
            />
          )}
          <Button
            callEvent={(e) => handleClick(e)}
            className={`button07 buttonDefault  ${
              !disableRuleIconModal && "buttonIconText"
            }`}
            ico={MdRocketLaunch}
            text={
              userIsAdmin
                ? "Conheça a LMS/LXP by Afferolab"
                : "Solicitar o trial do LMS/LXP by Afferolab"
            }
          />
        </div>
      )}
    </>
  );
};

export default PctUpgrade;
