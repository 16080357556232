import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import "./style.scss";
import { IoMdClose } from "react-icons/io";
import { OriginNavigation, Props } from "./modalUpgradeCampaign.model";
import { Button } from "pctComponents/atoms";
import { updatePlan } from "actions";
import { connect } from "react-redux";
import PctUpgrade from "pctComponents/organisms/PctUpgrade";
import { Image} from "../../atoms";



const ModalUpgradeCampaing = NiceModal.create(({ obj, planUpgrade, updatePlan, ...props }: Props) => {
  const { configModal } = obj
  const modal = useModal();

  const ButtonCancel = ({ label }: { label: string }) => (
    <Button
      callEvent={() => closeModal()}
      className={"button03 button-default  buttonCancel"}
      text={label}
    />
  )

  const closeModal = () => {
    updateLocalStorage();
    modal.remove();
    obj.closedModal();
  }

  function addMinutesToDate(milliseconds: number): Date {
    const newDate = new Date();
    return new Date(newDate.getTime() + milliseconds);
  }

  const updateLocalStorage = () => {
    localStorage.setItem('campaing', JSON.stringify(addMinutesToDate(obj.sleepTime || 0)));
  }

  const removeLocalStorage =  () => {
      localStorage.removeItem('campaing')
  }

  const FeedbackUpgrade = () => {
    return (
      <div className="container">
        <div className="title">
          <h3>{configModal.title}</h3>
          <p>
            {configModal.description}
          </p>
        </div>
       
        {configModal.mediaType === 'image' ? (
          <div className="image-modal-campaign"> 
            <Image className="image-modal-upgrade" path={configModal.mediaUrl}></Image>

          </div>
        ) : (
          <div className="iframe">
            <iframe
              className="iframe-campaing"
              src={configModal.mediaUrl}
              title="camaping"
            />
           </div>
        )}
      
        <div className="footer right">
          <ButtonCancel label="AGORA NÃO" />
          <PctUpgrade
            callEvent={() => {
              closeModal(); 
              removeLocalStorage();
            }}
            disableRuleIconModal={true}
            updatePlan={updatePlan}
            originNavigation={OriginNavigation.header}
            hideNotification={true}
            {...planUpgrade}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={`ModalUpgradeCampaing`}>
      <div
        className={`modal-body-pct`}
      >
        <>
          <button className="closeBtn" onClick={closeModal}>
            <IoMdClose />
          </button>
          <div className="container-feedback-modal">
            <FeedbackUpgrade></FeedbackUpgrade>
          </div>

        </>

      </div>

    </div>
  );
});
const mapStateToProps = (store) => ({
  planUpgrade: store.planUpgrade
});

const mapDispatchToProps = (dispatch) => ({
  updatePlan: (item) => dispatch(updatePlan(item)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalUpgradeCampaing);
