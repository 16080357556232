import { propTypes } from 'react-bootstrap/esm/Image';
import { useHistory } from "react-router-dom";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { getProfileInfo, getContentTypeLabel } from "services/utils";
import { userEnrollments, getCertificate, getLevelNodeEnrollment } from "services/api";
import useDownloadFile from "./useDownloadFile";
import useEvaluation from "./useEvaluation";
import useNodeEnrollment from "./useNodeEnrollment";
import useContentEnrollment from "./useContentEnrollment";
import ModalRegistration from "../pctComponents/modals/ModalRegistration";
import ModalLoadingInfo from "../pctComponents/modals/ModalLoadingInfo";
import useBackControll from "./useBackControll";

import {
  EvaluationSVG,
  UnAvailable,
  FiledSVG,
  ExpiredSVG,
  FailedSVG,
  SucessSVG,
  InfoSVG, 
  GradeSVG,
  NodePlaceHolderSVG
} from "../pctComponents/svg";


type Props = {
  collection: BlockElement | any;
  id?: any;
  dataParent?: any;
  setProgressStateRef?: any;
  progressInfoRef?: any;
};

const useCards = ({
  collection,
  id,
  dataParent,
  setProgressStateRef,
  progressInfoRef,
}: Props) => {
  const history = useHistory();

 
  const modalLoadingNodeEnrollment = useModal("ModalLoadingContentEnrollment");
  NiceModal.register("ModalLoadingContentEnrollment", ModalLoadingInfo);

  const modalLoading = useModal("ModalLoadingInfo");
  const modalLoadingEvaluation = useModal("ModalLoadingInfo");
  const modalLoadingContentEnrollment = useModal(
    "ModalLoadingContentEnrollment"
  );

  const { isfluidNavigationFn } = useBackControll({});

  NiceModal.register("ModalLoadingInfo", ModalLoadingInfo);
  NiceModal.register("ModalLoadingEvaluation", ModalLoadingInfo);
  NiceModal.register("ModalLoadingContentEnrollment", ModalLoadingInfo);


  // TRATAMENTO PARA POSSÍBILITAR A MATRÍCULA EM NÓS SEM ENTRYPOINT
  if(collection.data.hasOwnProperty('parentEntryPoint') && !collection.data?.enrollment){ 
    if(collection.data.parentEntryPoint){
      collection = {
        data : collection.data.parentEntryPoint,
        collectionToGo : collection,
        elementType : "NODE"
      }
      collection.data.collectionToGo = collection.collectionToGo
    }
  }

  const image = () => {
    let tempImage: any = "";

    if (statusElement() === "ARCHIVED") {
      tempImage = {
        svg: FiledSVG(),
        image: collection.data.image ? collection.data.image : NodePlaceHolderSVG(),
      };
    }else if(!nodeAvailable()){
      tempImage = {
        svg: UnAvailable(),
        image: collection.data.image ? collection.data.image : NodePlaceHolderSVG(),
      };
    }else {
      tempImage =
        collection.elementType === "EVALUATION" &&  !collection.data.image
          ? EvaluationSVG()
          : collection.data.image ? collection.data.image : NodePlaceHolderSVG();
      
    }

    return tempImage;
  };

  const mobileImage = () => {
    let tempImage = collection.data.mobileImage;
    return tempImage;
  };

  const verifyCompletedStatus = () => {

    ///Caso ele tenha status ele vai pelo status
    if(isEnrollment().status){
      
      if(isEnrollment().status ==='COMPLETED' || isEnrollment().status ==='PASSED' ){
        return true;
      }else{
        return false;
      }

    }else{
      ///Caso ele não tenha status de matricula ele vai pelo progresso 
      if(progressInfoRef.current === 100){
        return true;
      }else{
        return false;
      }
    }
  } 

  const concluidedText = () => {
    
    let standardStatus = () => {
      const sts =
      isEnrollment().status === "NOT_ATTEMPTED"
        ? `Não iniciado`
        : `Em andamento`;

      return verifyCompletedStatus()
        ? "<div class='dis2'><i class='fa fa-check' aria-hidden='true'></i> &nbsp; Concluído</div>"
        : `<div class='dis3'>${sts}</div>`;
    };

    const freeContentStatus = () => {
      const sts =
      collection.data.active != 1
        ? `Não iniciado`
        : `Em andamento`;

      return collection.data.contentProgress === 100
        ? "<div class='dis2'><i class='fa fa-check' aria-hidden='true'></i> &nbsp; Concluído</div>"
        : `<div class='dis3'>${sts}</div>`;
    };

    const expiredStatus = () => {
      return `<div className='dis4'> ${ExpiredSVG()} Expirado </div>`;
    };


    const resStatus = () => {

      const statusEl = (statusElement() === "FAILED");
      const evaluationStatus = collection.elementType !== "EVALUATION";
     
      const grade = isEnrollment().grade;
      const tooltipText = `A nota é a média de todas as avaliações realizadas até o momento.`;
      

      const InfoText = () => {
        return `<div class="tooltipIt" tooltip="${tooltipText}">
                    ${InfoSVG()}
                </div>`
      }

      // GradeSVG
      return `<div className='disStatus ${ statusEl ? 'disFailed': '' }'> 
                    ${grade ? `<div class="infoGrade">
                                  ${GradeSVG()}
                                  <div>Nota: ${grade}%</div>
                                </div>` : ``
                      }
                    ${(evaluationStatus)?InfoText():''}
                    <div class="disRef">
                      ${ statusEl ? FailedSVG() : '<i class="fa fa-check" aria-hidden="true"></i> &nbsp;' } 
                      <div>${statusEl ? 'Reprovado' : 'Aprovado'}</div>
                    </div>
              </div>`;
    };

    // const feeContentStatus = () => {
    //   return `<div className='dis6'> </div>`;
    // };

    if (statusElement() === "EXPIRED") {
      standardStatus = expiredStatus;
    }

    if (statusElement() === "FAILED" || statusElement() === "PASSED" ) {
      standardStatus = resStatus;
    }

    if (isFreeContent() ) {
      standardStatus = freeContentStatus
    }

    

    return standardStatus();
  };

  const currentLabel = () => {
    return collection.data?.label
      ? collection.data?.label
      : getContentTypeLabel(collection.data.idContentType);
  };

  const sanitizeText = (phrase, limit) => {
    const ret =
      phrase.length > limit ? phrase.substring(0, limit) + "..." : phrase;

    // phrase.substring(0, limit) + "[...]"
    return ret;
  };

  const currentBreadText = () => {
    let breadLabel = "";

    if (collection.data.parentNodes) {
      collection.data.parentNodes.map(
        (_nodeElement: LearningNode, i: number) => {
          const longTitle = _nodeElement.title;
          const shortTitle =
            _nodeElement.shortTitle !== ""
              ? _nodeElement.shortTitle
              : longTitle;
          const currentLabel = sanitizeText(shortTitle?.toUpperCase(), 25);

          if (i < collection.data.parentNodes?.length - 1) {
            if (i === 0) {
              breadLabel += `<span title="${longTitle}" class=''>${currentLabel}</span>  <b>•</b>  `;
            } else {
              breadLabel += `<span title="${longTitle}"> ${currentLabel} </span>  <b>•</b>  `;
            }
          } else {
            if (i === 0) {
              breadLabel += `<span title="${longTitle}" class=''>${currentLabel}</span>`;
            } else {
              breadLabel += `<span title="${longTitle}">${currentLabel}</span>`;
            }
          }

          return _nodeElement;
        }
      );
    } else {
      breadLabel = "";
    }

    return breadLabel;
  };

  /*
    ENTRYPOINT (0, 1) -> É UM NÓ QUE PODE TER TURMA...SE FOR ZERO NUNCA VAI TER A OPÇÃO DE SE MATRICULAR. 
    ENROLLMENT -> QUANDO EXIXTIR SIGNIFICA QUE O USUÁRIO JÁ POSSUÍ UMA MATRICULA.
    hasTeamAvailable -> SE EXISTE TURMA DISPONÍVEL PARA O USUÁRIO SE MATRICULAR.
    selfEnrollment (0, 1) -> SE FOR VERDADEIRO E NÃO TIVER TURMA DISPONÍVEL ELE CRIA A MATRICULA AUTOMÁTICA, CASO CONTRÁRIO MOSTRA A LISTA DE TURMAS DISPONÍVEIS.
  
    
    MANDATORY (0, 1) -> OBRIGATÓRIO
    */

  const isFreeContent = () => {
    let blFreeContent = false;

    if(collection.data.hasOwnProperty('contentEnrollment') == false && collection.elementType == 'CONTENT'){
      blFreeContent = true;
    } 

    return blFreeContent;

  }

  const isEnrollment = () => {

    const evalEnroll = () => {
      return ( collection.elementType === "EVALUATION" && evaluationEnrollment().startDate ) ? evaluationEnrollment() : null;
    }

    const freeContentFakeEnrollment = () => {
      return ( isFreeContent() ? { isFreeContent:true } : null );
    }

    

    const isEnrollmentTemp =
      collection.data?.nodeEnrollment ||
      collection.data?.enrollment ||
      collection.data?.contentEnrollment ||
      evalEnroll() ||
      freeContentFakeEnrollment() ||
      isCatalog();
    return isEnrollmentTemp;
  };

  const hasEnrollment = () => {
    return isEnrollment();
  };

  const isCatalog = () => {
    const isCatalogTemp = collection.data?.entryPoint === 0 && collection.data?.nodeType === "ORGANIZATION";
    return isCatalogTemp;
  };

  const evaluationEnrollment = () => {
    let tempEvaluationEnrollment = {} as any;
    if (collection.elementType === "EVALUATION") {

      let progress = 0;
      let statusEvaluation: string | null = null;

      if(collection.data.evaluationType === 'REACTION'){
        progress =  collection.data.questionnaire
          ? collection.data.questionnaire.finishDate
            ? 100
            : 50
          : 0
      }

      if(collection.data.evaluationType === 'KNOWLEDGE'){
        // progress = (collection.data.questionnaire && collection.data.questionnaire.grade) 
        //   ? collection.data.questionnaire.grade
        //   : 0
        
        /// Verifica o numero de tentativa 

        if( collection.data.questionnaire ){

          if(collection.data.questionnaire.grade){
            if( collection.data.questionnaire.grade >= collection.data.evaluationMasteryScore ){
              statusEvaluation = 'PASSED';
              progress = 100;
             }else{
              statusEvaluation = 'FAILED';
              progress = 100;
             }
          }else{

            if(!collection.data.questionnaire.finishDate){
              statusEvaluation = 'IN_PROGRESS';
              progress = 50;
            }else{
              statusEvaluation = 'FAILED';
              progress = 100;
            }
            
          }

        }
         
      }

      
      tempEvaluationEnrollment = {
        evaluationType: collection.data.evaluationType,
        content: {
          id: null,
        },
        enrollment: {
          idEnrollment: collection.idEnrollment,
        },
        finishDate: collection.data.questionnaire
          ? collection.data.questionnaire.finishDate
          : null,
        grade: collection.data.questionnaire ? collection.data.questionnaire.grade : null,
        idContentEnrollment: null,
        lastAccessDate: null,
        progress: progress,
        startDate: collection.data.questionnaire
          ? collection.data.questionnaire.startDate
          : null,
        status: statusEvaluation,
        user: {
          idUser: getProfileInfo().idUser,
        },
        idNodeEnrollment: collection.idNodeEnrollment,
      };
    }else{
      tempEvaluationEnrollment = null;
    }

    return tempEvaluationEnrollment;
  };

  //APARECE O BOTÃO PARA O USUÁRIO SE MATRICULAR.
  const canEnrollment = () => {
    const canEnrollmentTemp = (collection.data?.selfEnrollment === 1 && collection.data.nodeType != "ORGANIZATION") ||
                              (collection.data?.hasTeamAvailable === true && collection.data.nodeType != "ORGANIZATION");
    return canEnrollmentTemp;
  };


  //VERIFICANDO SE TENHO TURMAS CRIADAS OU NÃO.
  const noTeamAvaliable = () => {
    const noTeamAvaliableTemp = !collection.data?.hasTeamAvailable;
    return noTeamAvaliableTemp;
  };

  const teamDateExpired = () => {
    const finalDate = new Date(isEnrollment().team.finalDate).getTime();
    const currentDate = new Date().getTime();

    const isDateExpired = finalDate ? currentDate > finalDate : false;

    return isDateExpired;
  };

  const allowTeamReenrollment = () => {
    const allowReenrollmentTemp =
      isEnrollment().team.allowReenrollment && teamDateExpired() == false;
    return allowReenrollmentTemp;
  };


  const statusRegistered = () => {
    let statusRegisteredTemp: string = "inactive";

    if (isCatalog()) {
      statusRegisteredTemp = "isCatalog";
    } else if(isFreeContent()){
      statusRegisteredTemp = "freeContent";
    } else if (
      ( isEnrollment().hasOwnProperty("idNodeEnrollment") &&
      prerequisitesMet() ) || (collection.data.hasOwnProperty('parentEntryPoint') && collection.data?.enrollment && !collection.data?.nodeEnrollment)
    ) {
      statusRegisteredTemp = "isNodeEnrollment";
    } else if (isEnrollment() && prerequisitesMet()) {
      if (
        (isEnrollment().status === "EXPIRED" ||
          isEnrollment().status === "FAILED") &&
        allowTeamReenrollment() == false &&
        collection.data?.hasTeamAvailable === false &&
        collection.data.selfEnrollment === false
      ) {
        statusRegisteredTemp = "inactive";
      }else if (
        (isEnrollment().status === "EXPIRED" ||
          isEnrollment().status === "FAILED") &&
        allowTeamReenrollment() == false &&
        collection.data?.hasTeamAvailable === false &&
        collection.data.selfEnrollment === true
      ) {
        //PERMITINDO REMATRÍCULA MESMO SE NÃO TIVER TURMA OU SE A TURMA NÃO PERMITIR A REMATRÍCULA, MAS O NÓ PERMITIR A AUTO MATRÍCULA.
        statusRegisteredTemp = "re-enroll";
      }else if (
        (isEnrollment().status === "EXPIRED" ||
          isEnrollment().status === "FAILED") &&
        allowTeamReenrollment() == false &&
        collection.data?.hasTeamAvailable === true
      ) {
        statusRegisteredTemp = "re-enroll";
      }else if (
        (isEnrollment().status === "EXPIRED" ||
          isEnrollment().status === "FAILED") &&
        allowTeamReenrollment() == false &&
        canEnrollment() === false
      ) {
        statusRegisteredTemp = "inactive";
      } else if (
        (isEnrollment().status === "EXPIRED" ||
          isEnrollment().status === "FAILED") &&
        allowTeamReenrollment() == false &&
        canEnrollment() === false
      ) {
        statusRegisteredTemp = "re-enroll";
      } else if (
        (isEnrollment().status === "EXPIRED" ||
          isEnrollment().status === "FAILED") &&
        (allowTeamReenrollment() == true ||  collection.data.selfEnrollment == true)
      ) {
        statusRegisteredTemp = "re-enroll";
      } else {
        if(isEnrollment().status ==='COMPLETED' && isEnrollment().team.allowAccessUponCompletion === 0){
          statusRegisteredTemp = "inactive";
        }else{
          statusRegisteredTemp = "isEnrollment";
        }
        
      }

      
    } else if (canEnrollment() === true && noTeamAvaliable() === false) {
      statusRegisteredTemp = "canEnrollment";
    } else if (canEnrollment() === true && noTeamAvaliable() === true) {
      statusRegisteredTemp = "canEnrollment";
    } else if (
      collection.data?.selfEnrollment === 0 &&
      collection.data.entryPoint === 0
    ) {
      statusRegisteredTemp = "inactive";
    }else if(collection.elementType === "EVALUATION") {
      statusRegisteredTemp = "isNodeEnrollment";
    }

    return statusRegisteredTemp;
  };

  const classNotStartedYet = () => {
    if(!collection?.data?.enrollment?.team?.initialDate){
      return false
    }
    const initialDate = new Date(collection?.data?.enrollment?.team?.initialDate).getTime();
    const currentDate = new Date().getTime();
    return initialDate ? initialDate > currentDate : false;
  }

  const statusElement = () => {
    //"NOT_ATTEMPTED", "IN_PROGRESS", "EXPIRED","ARCHIVED", "PUBLISHED"
    //"COMPLETED", "PASSED", "FAILED",
    let currentStatus = "NOT_ATTEMPTED";
    const currentEnrollment = isEnrollment();

    if (collection.data.status === "ARCHIVED") {
      currentStatus = "ARCHIVED";
    } else {
      if (currentEnrollment) {
        currentStatus = currentEnrollment.status;
      } else {
        currentStatus = collection.data.status;
      }
    }

    return currentStatus;
  };

  const nodeAvailable = () => {
    return !collection.data.hasOwnProperty('available') 
           ? true 
           : isEnrollment()
           ? true 
           : collection.data.available;
  };

  const initialProgressState = () => {
      let progressTemp = collection.data.nodeEnrollment
        ? (collection.data.nodeEnrollment?.progress as number)
        : collection.data.enrollment
        ? collection.data.enrollment?.progress
        : collection.data.contentEnrollment
        ? collection.data.contentEnrollment?.progress
        : 0;

    if (collection.elementType === "EVALUATION") {
      progressTemp = evaluationEnrollment().progress;
    }

    if(isFreeContent()){
      return progressTemp = collection.data.contentProgress ? collection.data.contentProgress : null;
    }else{
      return progressTemp ? progressTemp : 0;
    }
    
    
  };

  const prerequisitesMet = () => {
    const prerequisitesMetTemp =
      !collection.data.sequential || verifyCompletedStatus() || collection.data.entryPoint === 1
        ? true
        : collection.prerequisitesMet;

    return prerequisitesMetTemp;
  };

  const hasCertificate = () => {
    let hasCertificateTemp = false;

    if (collection.data?.nodeEnrollment) {
      if (collection.data?.nodeEnrollment.team) {
        if (collection.data?.nodeEnrollment.team?.idCertificate) {
          if (
            !collection.data?.nodeEnrollment.team?.enableCertificateForChildren
          ) {
            hasCertificateTemp = false;
          } else {
            hasCertificateTemp = true;
          }
        }
      }
    } else if (collection.data.enrollment) {
      if (collection.data?.enrollment.team) {
        if (collection.data?.enrollment.team?.idCertificate) {
          hasCertificateTemp = true;
        }
      }
    }

    return hasCertificateTemp;
  };

  const routerLink = () => {
    let routerLinkTemp: string = "/";

    if (collection.elementType === "NODE") {
      if (collection.data.nodeCode?.split(".").length === 1) {
        return routerLinkTemp;
      }

      if(collection.data.hasOwnProperty('parentEntryPoint') && collection.data?.enrollment && !collection.data?.nodeEnrollment){
        //TRATAMENTO PARA A PESQUISA, POIS QUANDO É FEITA A MATRICULA NO PAI E O OBJETO AINDA NÃO FOI INICIADO O NODEENROLLMENT AINDA NÃO EXISTE E PRECISO DIRECIONAR O USUÁRIO PARA O NÓ ATUAL E NÃO PARA O NÓ PRINCIPAL COM ENTRYPOINT.
        routerLinkTemp = `/level/${collection.data?.enrollment?.idEnrollment}/${collection.data.nodeCode}`;
      }
      else if (
        collection.data?.enrollment &&
        (!collection.data?.hasOwnProperty("nodeEnrollment") ||
          !collection.data?.nodeEnrollment)
      ) {
        routerLinkTemp = `/level/${collection.data?.enrollment.idEnrollment}`;
      } else if (collection.data?.nodeEnrollment) {

        //FAZER O ENROLLMENT PASSANDO O NODE_ENROLLMENT DO PAI, POIS OS FILHOS AINDA POSSUEM O ENROLLMENT NULL
        const nodeCode = collection.data?.nodeEnrollment.node?.nodeCode
          ? collection.data?.nodeEnrollment.node?.nodeCode
          : collection.data.nodeCode;
        routerLinkTemp = `/level/${collection.data?.nodeEnrollment.enrollment?.idEnrollment}/${nodeCode}`;
        
      } else {
        routerLinkTemp = `/catalog/${collection.data.nodeCode}${
          collection.data.nodeEnrollment
            ? "/" + collection.data.nodeEnrollment.enrollment?.idEnrollment
            : ""
        }`;
      }
    } else if (collection.elementType === "CONTENT") {
     
      const idContentType = collection.data.idContentType;
      const { idElement, idNodeEnrollment, idEnrollment, data } = collection;

      if(isFreeContent()){
        // routerLinkTemp = `/content/${idElement}`;
        routerLinkTemp = `/contentNode/${idElement}`;
      }
      else if (idContentType === 26) {
        ///SCORM
        //QUANDO O ID FOR ZERO SIGNIFICA QUE O USUÁRIO ESTÁ NA NAVEGAÇÃO FLUÍDA
        routerLinkTemp = `/lms/${
          Number(id) !== 0 ? id : collection.idEnrollment
        }/${collection.idElement}/${idNodeEnrollment}/${
          Number(id) !== 0 ? 0 : 1
        }`;
      }
      else {
        
        if (data.contentEnrollment?.idContentEnrollment) {
          routerLinkTemp = `/contentNode/${idElement}/${idNodeEnrollment}/${idEnrollment}/${
            getProfileInfo().idUser
          }`; 
        }
      }
    }

    return routerLinkTemp;
  };

  const openModalRegistration = () => {
    NiceModal.show(ModalRegistration, {
      obj: collection.data ? collection.data 
        : collection,
    });
  }

  const handleCardMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
   
    if(collection.data.nodeType === "ORGANIZATION"){
      history.push(`${routerLink()}`);
    }else if(isFreeContent()){
      //CONTEÚDO LIVRE SEM NECESSIDADE DE MATRÍCULA.    
      history.push(`${routerLink()}`);
    } else if (
      collection.elementType === "CONTENT" ||
      collection.elementType === "EVALUATION"
    ) {
      
      isfluidNavigationFn(true);

      if (collection.elementType === "EVALUATION") {
        if (!evaluationEnrollment().startDate) {
          //CHAMANDO ENDPOINT PARA INICIAR A AVALIAÇÃO E NO RETORNO ENVIAMOS PARA A PÁGINA DA AVALIAÇÃO
          callEvaluation();
        } else {
          // dataParent.node.nodeEnrollment.team.idTeam
          history.push(
            `/evaluations/${evaluationEnrollment().enrollment.idEnrollment}/${
              collection.data.questionnaire.idEvaluation
            }/${collection.data.questionnaire.idQuestionnaire}`
          );
        }
      } else if (
        !collection.data.contentEnrollment?.idContentEnrollment &&
        collection.elementType === "CONTENT" &&
        collection.data.idContentType !== 26
      ) {
        callContentEnrollment();
      } else {
        history.push(`${routerLink()}`);
      }
      // }
    } else if (collection.data?.nodeEnrollment) {
      if (
        !collection.data?.nodeEnrollment?.idNodeEnrollment &&
        collection.elementType === "NODE"
      ) {
        callNodeEnrollment({
          collection: collection,
          modalLoadingNodeEnrollment: modalLoadingNodeEnrollment,
          _parent: dataParent,
          _onFinished: false,
        });
      } else {
        history.push(`${routerLink()}`);
      }
    } else if (
      isEnrollment() &&
      isEnrollment().status != "EXPIRED" &&
      isEnrollment().status != "FAILED"
    ) {
      history.push(`${routerLink()}`);
    } else if (
      (isEnrollment().status == "EXPIRED" ||
      isEnrollment().status == "FAILED") &&
      allowTeamReenrollment() == false &&
      collection.data?.hasTeamAvailable === true
    ) {
      // history.push(`${routerLink()}`);
      //A TURMA NÃO PERMITE REMATRICULA E TEMOS OUTRAS TURMAS DISPONÍVEIS, ENTÃO VOU FAZER A REMATRICULA EM OUTRA TURMA.
      openModalRegistration()

    }
    else if (
      (isEnrollment().status == "EXPIRED" ||
        isEnrollment().status == "FAILED") &&
        allowTeamReenrollment() == true
    ) {
      //A TURMA PERMITE REMATRICULA E A TURMA AINDA ESTÁ DISPONÍVEL, ENTÃO VOU FAZER A REMATRICULA NA MESMA TURMA.
      modalLoading.show({
        obj: {
          message: "Aguarde, realizando <span class='primaryColor'>a sua rematrícula</span>.",
          closeBtn: true,
          loadding: true,
        },
      });

      userEnrollments({
        idTeam: isEnrollment().team.idTeam,
        nodeCode: collection.data?.nodeCode,
        idUser: getProfileInfo().idUser,
      })
        .then((response) => {
          setTimeout(() => {
            modalLoading.remove();
            if (response !== undefined) {
              if (collection.elementType === "NODE") {
                history.push(`${`/level/${response.data.idEnrollment}`}`);
              }
            }
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (
      (isEnrollment().status == "EXPIRED" ||
        isEnrollment().status == "FAILED") &&
      allowTeamReenrollment() == false &&
      noTeamAvaliable() === false
    ) {
      //CASO NÃO SEJA POSSÍVEL SE REMATRICULAR NA MESMA TURMA E EXISTEM TURMAS DISPONÍVEIS PARA REALIZAR REMATRICULA.
      
      openModalRegistration()
    } else if (
      (isEnrollment().status == "EXPIRED" ||
        isEnrollment().status == "FAILED") &&
      allowTeamReenrollment() == false &&
      noTeamAvaliable() === true
    ) {
      //FAZER A AUTO REMATRÍCULA EM UMA DAS TURMAS DISPONÍVEIS.
      modalLoading.show({
        obj: {
          message: "Aguarde, realizando <span class='primaryColor'>a sua rematrícula</span>.",
          closeBtn: false,
          loadding: true,
        },
      });

      userEnrollments({
        nodeCode: collection.data?.nodeCode,
        idUser: getProfileInfo().idUser,
      })
        .then((response) => {
          setTimeout(() => {
            modalLoading.remove();
            if (response !== undefined) {
              if (collection.elementType === "NODE") {
                history.push(`${`/level/${response.data.idEnrollment}`}`);
              }
            }
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (canEnrollment() === true && noTeamAvaliable() === false) {
      
      openModalRegistration()
    } else if (noTeamAvaliable() === true && canEnrollment() === true) {
      //Fazer auto matrícula
      modalLoading.show({
        obj: {
          message: "Aguarde, realizando <span class='primaryColor'>a sua matrícula</span>.",
          closeBtn: false,
          loadding: true,
        },
      });
      // Teste
      userEnrollments({
        nodeCode: collection.data?.nodeCode,
        idUser: getProfileInfo().idUser,
      })
        .then((response) => {
          setTimeout(() => {
            modalLoading.remove();
            if (response !== undefined) {
              if (collection.elementType === "NODE") {
                history.push(`${`/level/${response.data.idEnrollment}`}`);
              }
            }
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if( canEnrollment() === false){
      //LOGICA PARA CONSEGUIR SE MATRICULAR EM CONTEÚDOS SEM ENTRYPOINTS, MAS QUE POSSUEM NÍVEIS ACIMA DELE QUE POSSUEM ENTRYPOINT
      console.log('ESTA PÁGINA NÃO POSSUI MATRÍCULA E NÃO PERMITE UMA MATRÍCULA.')
    } else {
      // history.push(`${routerLink()}`);
      console.warn("Router not found!");
    }

    e.stopPropagation();
  };

  const handleCertificateMouseEvent = (e: React.MouseEvent<HTMLElement>) => {
    if ( !verifyCompletedStatus() ) return;

    modalLoading.show({
      obj: {
        message: "Aguarde, gerando e baixando o certificado!",
        closeBtn: false,
        loadding: true,
      },
    });

    const objDownload = {
      idUser: getProfileInfo().idUser,
      idEnrollment: collection.data.enrollment
        ? collection.data.enrollment.idEnrollment
        : collection.data?.nodeEnrollment?.enrollment?.idEnrollment,
      idNodeEnrollment: collection.data?.nodeEnrollment?.idNodeEnrollment,
    };

    getCertificate(objDownload)
      .then((response) => {
        setTimeout(() => {
          modalLoading.remove();
        }, 2000);

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");

        link.href = url;
        link.download = `${collection.data.title?.replace(
          /[^\w]/g,
          ""
        )}_certificate.pdf`;
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });

    e.stopPropagation();
  };

  const { callDownload } = useDownloadFile({
    contentLocation: collection.data.contentLocation,
    collection,
    setProgressStateRef,
    modalLoading,
  });

  const { callEvaluation } = useEvaluation({
    collection,
    evaluationEnrollment,
    modalLoadingEvaluation,
  });

  const { callContentEnrollment } = useContentEnrollment({
    collection,
    modalLoadingContentEnrollment,
    titleParent: collection.data.title,
  });

  const { callNodeEnrollment } = useNodeEnrollment();

  

  return {
    image,
    mobileImage,
    isEnrollment,
    hasEnrollment,
    canEnrollment,
    noTeamAvaliable,
    hasCertificate,
    initialProgressState,
    evaluationEnrollment,
    concluidedText,
    currentLabel,
    statusRegistered,
    statusElement,
    prerequisitesMet,
    routerLink,
    handleCardMouseEvent,
    handleCertificateMouseEvent,
    currentBreadText,
    callDownload,
    callNodeEnrollment,
    nodeAvailable,
    modalLoadingNodeEnrollment,
    classNotStartedYet,
    verifyCompletedStatus
  };
};

export default useCards;
