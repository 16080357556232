import { License } from "reducers/license";

export enum Profile {
  admim = "admin",
  client = "client",
}
export enum OriginNavigation {
  header = 'header',
  fluidNavigation = 'fluid navigation'
}

export interface configModal {
  title?: string,
  description?: string,
  mediaType?: string,
  mediaUrl?: string,
   
}

export type Props = {
  obj: {
    closedModal: (e?: string | null) => void;
    configModal: configModal,  
    idBlock? : number,
    idNode?: number,
    sleepTime?: number
  };
  planUpgrade: License,
  updatePlan: (event: any) => void

};