import { useEffect, useState } from 'react'

import { getLevelNodeEnrollment } from "services/api";

import {
  getProfileInfo,
} from "services/utils";



const useNotificationCampaigns= ({props, setCampaign}) => {

    const [contItem, setIdContentIt] = useState(null);
    const [nodeCampaing, setNodeCampaing] = useState<any>(null);

    const idUser = getProfileInfo()?.idUser;

    const verifyCompleteCampaign = (dataNodeContentFinish) => {
        ///Verifica se a matricula, ao entrar no conteúdo, o status NÃO é: COMPLETED e PASSED 
        if (
            nodeCampaing.nodeEnrollment.status != "COMPLETED" && 
            nodeCampaing.nodeEnrollment.status != "PASSED"
        ) {
            ///Verifica se a matricula é COMPLETED ou PASSED, depois de completar o conteúdo em que esta. 
            if(dataNodeContentFinish.nodeEnrollment.status === "COMPLETED" || 
                dataNodeContentFinish.nodeEnrollment.status === "PASSED" ){
                    setCampaign({earnPointsStatus:true})
            }
        }

        //reset
        setNodeCampaing(null);
        setIdContentIt(null);
    };

    const verifyLevelNodeEnrollment = ( idEnrollment, resLevelNodeEnrollment ) =>{
        getLevelNodeEnrollment(idUser, idEnrollment, null).then((response) => {
            if (response.data.node.hasCampaign) {
                resLevelNodeEnrollment(response);
            }
        });
    }

    const verifyCampaing  = ( idEnrollment, idContentItem ) => {
        
        ///Valida se ele esta no mesmo content
        if (contItem === null || idContentItem === contItem) {

            verifyLevelNodeEnrollment(idEnrollment, (response)=>{
                ///Entrou a primeira vez
                if (nodeCampaing === null) {
                    setNodeCampaing(response.data.node);
                    setIdContentIt(idContentItem);
                }else{
                    ///Finalizou
                    verifyCompleteCampaign(response.data.node);
                }
            })

        }else{
            ///Mudou de content
            verifyLevelNodeEnrollment(idEnrollment, (response)=>{
                setNodeCampaing(response.data.node);
                setIdContentIt(idContentItem);
            });
        }
    }

   const controlEvaluation  = (contentNavigation ) => {
        if (contentNavigation && contentNavigation.evaluation) {
            setTimeout(()=>{
                const { idEnrollment, idEvaluation } = contentNavigation.currentItem;
                verifyCampaing( idEnrollment, idEvaluation );
            }, 1000* 1 );
        }
    }

    const controlScorm  = (contentNavigation ) => {
        if (contentNavigation && contentNavigation.objScorm) {
            setTimeout(()=>{
                const { idEnrollment, idContentItem } = contentNavigation.rootContent;
                verifyCampaing( idEnrollment, idContentItem );
            }, 1000* 1 );
        }
    }

    const controlContent  = (contentNavigation ) => {
        if (contentNavigation && contentNavigation.contentEnrollment) {
            const { idEnrollment } = contentNavigation.contentEnrollment;
            const { idContentItem } = contentNavigation.content;
            verifyCampaing( idEnrollment, idContentItem );
        }
    } 
    
    useEffect(() => {

        const contentNavigation = props.configHeaderFooter.contentNavigation;

        controlContent(contentNavigation);
        controlScorm(contentNavigation);
        controlEvaluation(contentNavigation);

    }, [props]);
 
}

export default useNotificationCampaigns

