import "./style.scss";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect, useHistory } from "react-router-dom";

import Form from "react-bootstrap/Form";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ModalOnboard from "../../modals/ModalOnboard";
import ModalTopicsOfInterest from "../../modals/ModalTopicsOfInterest";

import {
  getMenuData,
  setLoginData,
  setUserAgreement,
  logout,
  renewlogin,
  getFeatures,
} from "../../../services/api";
import {
  getProfileInfo,
  getAppDetails,
  getColors,
} from "../../../services/utils";

import { GrFormNextLink } from "react-icons/gr";

import { updateConfigHeaderFooter } from "../../../actions";
import { Text, Preloader, Button } from "../../atoms";

const Terms = (props) => {
  const [communication, setCommunication] = useState(false);
  const [showOnBoard, setShowOnBoard] = useState(false);
  const [termos, setTermos] = useState(false);
  const [communicationAccepted, setCommunicationAccepted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [redirectHome, setRedirectHome] = useState(false);
  const [onBoardUrl, setOnBoardUrl] = useState(null);
  const history = useHistory();
  const { updateConfigHeaderFooter } = props;

  useEffect(() => {
    updateConfigHeaderFooter({ headerStyle: "hide", footerStyle: "hide" });
    const menuData = getMenuData();

    const { agreementVersion, userLicenceTermsAccepted } = menuData.loginInfo;
    setRedirectHome(agreementVersion === userLicenceTermsAccepted);
  }, []);

  const getAppIcon = () => {
    const appDetails = getAppDetails();
    const menuHeaderIcon = getColors("menu").headerIcon;
    return menuHeaderIcon || appDetails?.headerIcon;
  };

  function _logout() {
    logout();
    history.push("/login");
    // setRedirectLogin(true);
  }

  function updateTerms() {
    let menuData = getMenuData();
    const { agreementVersion } = menuData.loginInfo;

    menuData.loginInfo.userLicenceTermsAccepted = agreementVersion;
    let user = getProfileInfo();

    let data = {
      communicationAccepted: communication ? 1 : 0,
      userLicenceTermsAccepted: agreementVersion,
      // "smsNotification": smsNotification ? 1 : 0,
      // "emailNotification": emailNotification ? 1 : 0,
    };
    setLoginData(menuData);
    setUserAgreement(user.idUser, data);
  }

  const openModalOnboard = () => {
    const { userLicenceTermsAccepted, onBoardScreen } = getMenuData().loginInfo;
    if (userLicenceTermsAccepted === 0 && onBoardScreen) {
      NiceModal.show(ModalOnboard, {
        obj: {
          closedModal: (e) => {
            // updateTerms()
            setRedirectHome(true);
          },
        },
      });
    } else {
      // updateTerms()
      setRedirectHome(true);
    }
  };

  function continuar() {
    updateTerms();
    getFeatures("RECOMMENDATION_BLOCK")
    .then((res) => {
      const active = res.data?.active;
      if(active){
        NiceModal.show(ModalTopicsOfInterest, {
          obj: {
            closedModal: (e) => {
              openModalOnboard();
            },
          },
        });
      }else{
        openModalOnboard();
      }
     
    })
 
  }

  function getTerms() {
    let terms = "";

    try {
      terms = getMenuData().loginInfo.agreementTerms;
    } catch {
      return "";
    }
    return terms;
  }

  if (redirectLogin) {
    return <Redirect to="/login" />;
  }

  if (redirectHome) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {
        <div className="terms-container">
          <div className="terms-logo">
            <img src={getAppIcon()} alt="logo" />
            <hr />
          </div>
          <div className="terms-wrapper">
            <div className="term-container-html">
              <div className="term-container">
                <div dangerouslySetInnerHTML={{ __html: getTerms() }} />
              </div>
            </div>

            <label>
              <input
                type="checkbox"
                className="chb chb-3"
                checked={termos}
                onChange={(e) => setTermos(e.target.checked)}
              />
              Li e aceito os termos listados acima.
            </label>

            {/* {showError && <div className="term-error"> Você precisa aceitar os termos para continuar. </div>} */}

            {communicationAccepted && (
              <>
                <Form.Check
                  onChange={(e) => setCommunication(e.target.checked)}
                  type="checkbox"
                  label="Aceito receber e-mail ou outras comunicações."
                  checked={communication}
                />
              </>
            )}

            <div className={"btnContainer"}>
              <Button
                className="decBtn button01"
                text="Declinar"
                callEvent={() => _logout()}
              />
              <Button
                className={`button01 contBtn ${!termos ? "disabledBtn" : ""}`}
                text="Continuar"
                ico={GrFormNextLink}
                callEvent={() => continuar()}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Terms);
