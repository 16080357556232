import './style.scss'

import React from "react";
import { useHistory } from 'react-router-dom'
import { Text, Button } from '../../atoms'
import { TiInputChecked } from "react-icons/ti"
import { GrFormNextLink } from 'react-icons/gr'

type Props = {
    className?: string
    css?: object
    collection: any
}


const CardInteraction = ({ collection, className="", css={} }: Props) => {
    
    const { idContentItem, nodeLevel, description,  name} = collection;
    
    let pathNode= '';
    if(idContentItem){
        pathNode = `/contentNode/${idContentItem}`; 
    }else{
        pathNode = `/level?nodeLevel=${nodeLevel}`;
    }  
   
    return (
        <div className="pctMolecule pctCard pctCardInteraction" style={css} >
            
            <div className='containerData'>
                <div className='dataIco'>
                    <TiInputChecked />
                </div>
                <div className='dataTxt'>
                    <Text component="h3" text={name} />
                    <Text component="p" text={description} className='titleCard' />
                </div>
            </div>

            
            
            <div className='containerButton'>
                <Button ico={ GrFormNextLink } link={ pathNode } text={"Iniciar agora"} className='button01'/>
            </div>

        </div>
    )

};

export default CardInteraction