import './style.scss';

import React, { useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { updateConfigHeaderFooter } from '../../../actions';
import { useFetch } from '../../../pctHooks/useFetch';
import { getProfileInfo } from 'services/utils';
import { Preloader, Text } from '../../atoms';
import TreeList from 'pctComponents/organisms/TreeList';
import { useHistory, useLocation } from 'react-router-dom';
import useBackControll from '../../../pctHooks/useBackControll';
import ErrorComponent from 'pctComponents/organisms/ErrorComponent';
import { propTypes } from 'react-bootstrap/esm/Image';

type idType = {
    idEnrollment?: string;
    idContentItem?: string;
    idNodeEnrollment?: string;
    isFluidNavigation?: string;
};

const Lms = (props) => {
    const { updateConfigHeaderFooter } = props;
    const { idEnrollment, idNodeEnrollment, idContentItem, isFluidNavigation } = useParams<idType>();
    const userProfile = getProfileInfo();
    const history = useHistory();
    const location = useLocation();
    const callSingle = useRef(false);
    const isComponentMounted = useRef(false);
    const { redirectFn } = useBackControll({});

    const { data, error } = useFetch({
        url: `learning/students/${userProfile.idUser}/enrollments/${idEnrollment}/nodeEnrollment/${idNodeEnrollment}/contents/${idContentItem}`,
    });
    let fetchData: any;

    useEffect(() => {
        isComponentMounted.current = true;
    }, []);

    if (error) return <ErrorComponent error={error} />;
    if (!data) return <Preloader />;

    fetchData = data;
    const contentData = fetchData.content;
    const contentEnrollment = fetchData.contentEnrollment ? fetchData.contentEnrollment : {};

    const lmsObject: any = {
        image: contentData.image,
        title: contentData.text,
        description: contentData.description,
        label: 'SCORM',
        nodeCode: null,
        enrollment: idEnrollment,
        idContentItem: idContentItem,
        lessons: contentData.scorm.scos,
        completed: contentEnrollment.progress === 100 ? true : false,
        scormStatus: contentEnrollment.status,
        progress: contentEnrollment.progress,
        grade: contentEnrollment.grade,
        scormProcessStatus: fetchData.content.scorm.status,
    };

    // TRATAMENTO PARA REALIZAR O REDIRECT QUANDO EXISTIR APENAS 1 SCO.
    if (
        isComponentMounted.current === true &&
        lmsObject.lessons.length === 1 &&
        callSingle.current === false &&
        lmsObject.scormProcessStatus !== 'PENDING'
    ) {
        const scoObj = lmsObject.lessons[0];
        callSingle.current = true;

        setTimeout(() => {
            history.push(
                `/lessons/${idEnrollment}/${idContentItem}/${scoObj.scoEnrollment.idSco}/${idNodeEnrollment}/single/${lmsObject.completed}/${fetchData.team[0].idTeam}`,
            );
            updateConfigHeaderFooter({
                footerStyle: 'hide',
                prevNavStyle: 'closeShow',
                backCloseText: 'FECHAR CONTEÚDO',
                contentNavigation: fetchData,
                scoSingle: true,
            });
        }, 1000);

        return <Preloader />;
    } else {
        updateConfigHeaderFooter({
            footerStyle: 'hide',
            prevNavStyle: 'closeShow',
            backCloseText: 'FECHAR CONTEÚDO',
            contentNavigation: fetchData,
        });
    }

    return (
        <div className="pctPage pctScormTree">
            <TreeList collection={lmsObject} treeType="scorm" />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateConfigHeaderFooter: (item) => dispatch(updateConfigHeaderFooter(item)),
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Lms);
