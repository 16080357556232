import React, { useState, useEffect, Fragment } from "react";

import "./style.scss";

import { getCategoriesCampaign } from "services/api";

import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { Button, Text, BreadCrumb } from "pctComponents/atoms";

type Props = {
  idgamification_campaign?: any;
  onclosedEvent?: any;
};

export const ModalRulesGamification = NiceModal.create(
  ({ idgamification_campaign, onclosedEvent = null }: Props) => {
    const modal = useModal();

    const [isCatalog, setIsCatalog] = useState([]);

    useEffect(() => {
      if (modal.visible)
        getCategoriesCampaign({ id: idgamification_campaign })
          .then((res) => {
            console.log(res.data);
            setIsCatalog(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
    }, [modal]);

    const closed = (e) => {
      if (onclosedEvent) onclosedEvent();
      modal.remove();
    };

    return (
      <div className="modalCampaingn">
        <div className="modal-body-pct">
          <div className="modal-container-campaign">
            <div className="ptcCampaignTitle">
              <Text component={"h2"} text={"Regras da campanha"} />
              <Text
                component={"p"}
                text={
                  "Para ganhar pontos, é necessário concluir os conteúdos dos treinamentos listados abaixo. Aproveite ao máximo para alcançar uma posição de destaque no Ranking da campanha. O desafio está lançado! Boa sorte!"
                }
              />
              {isCatalog.length > 0 && (
                <Text
                  component={"p"}
                  text={
                    "Aqui está o caminho completo, com todos os conteúdos dos treinamentos participantes desta campanha."
                  }
                  className={"subInfo"}
                />
              )}
            </div>

            {isCatalog.length > 0 && (
              <div className="ptcCampaignDescription">
                <div className="listMig">
                  {isCatalog.map((item, indice) => {
                    if (indice > 0) {
                      return (
                        <Fragment key={indice}>
                          <BreadCrumb catalogItem={item} />
                        </Fragment>
                      );
                    }
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="bottonBtn">
            <Button
              text="Fechar"
              className="button03"
              callEvent={(e) => closed(e)}
            />
          </div>
        </div>
      </div>
    );
  }
);
