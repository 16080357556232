import "./style.scss";

import React from "react";

import { Text } from '..'
import { FaAsterisk } from 'react-icons/fa';
import { BsChevronDown } from 'react-icons/bs';


type Props = {
  text?:string
  status?: boolean | number 
  css?: object
  className?: string
}

function Mandatory({ 
      text="É necessária a realização deste conteúdo para conclusão desta página." , 
      status = true, 
      css = {}, 
      className = "" }: Props) {
  
  return (
      (!!status ) ?
        (<div className={`pctAtom pctMandatory ${className}`} style={css}>
          < FaAsterisk/> 
          <div className="mandatoryInfo">
              <Text component={"small"} text={`${text?.toString()}`} />
              <div className="abaDown">
                < BsChevronDown />
              </div> 
          </div>
      </div>) : null
  )
}

export default Mandatory
