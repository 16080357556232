export const UPDATE_VALUE = "CLICK_UPDATE_VALUE";
export const UPDATE_HEADER = "CLICK_UPDATE_HEADER";
export const UPDATE_CONTENT_TYPE = "CLICK_UPDATE_CONTENT_TYPE";
export const UPDATE_SCORE_POINTS = "UPDATE_SCORE_POINTS";
export const UPDATE_POINTS = "UPDATE_VALUE_POINTS";

// [PTC]
export const CONFIG_HEADER_FOOTER = "CONFIG_HEADER_FOOTER";
export const UPDATE_COURSES_IN_PROGRESS = "UPDATE_COURSES_IN_PROGRESS";
export const CONFIG_CONTENTS_MAP = "CONFIG_CONTENTS_MAP";
export const CONFIG_CAMPAIGN = "CONFIG_CAMPAIGN";
export const CONFIG_HAS_ENROLLMENT_CAMPAIGN = "CONFIG_HAS_ENROLLMENT_CAMPAIGN";
export const UPDATE_LICENSE = 'UPDATE_LICENSE';
