import './style.scss';

import React, { useEffect, useState } from 'react';

import { NiceModalHandler } from '@ebay/nice-modal-react';

import { updatePlan } from 'actions';
import { connect } from 'react-redux';

import { Trial, ViewScreen, planUpgradeProps } from '../../@model/ManagerTrial.model';

import { IoMdClose } from 'react-icons/io';

import TermUpgrade from '../TermUpgrade';
import FormUpgrade from '../FormUpgrade';
import RequestUpgrade from '../RequestUpgrade';
import SelectedPlanUpgrade from '../SelectedPlanUpgrade';
import CalculatorPlanUpgrade from '../CalculatorPlanUpgrade';
import DetailPlanUpgrade from '../DetailPlanUpgrade';
import DialogUpgrade from '../DialogUpgrade';

import { useManagerTrial } from '../../context/contextManagerTrial';
import usePlan from 'pctHooks/usePlan';

const AdminUpgrade = ({ modal, planUpgrade }: { modal?: NiceModalHandler; planUpgrade?: planUpgradeProps }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { getActivePlanData, getAvailablePlans } = usePlan();
    const { viewScreenPlan, setViewScreenPlan, setListPlans, setCurrentPlan, setTrialStatusPlan } = useManagerTrial();

    const init = async () => {
        setLoading(true);
        const _getAvailablePlans = await getAvailablePlans();
        const flatPlans = _getAvailablePlans.flatMap(({ plans }) => plans);

        setListPlans(flatPlans);
        setTrialStatusPlan(Trial.notStarted);

        const _activePlan = await getActivePlanData();
        if (_activePlan) {
            const currentPlan = flatPlans.find((plan) => plan.idPlan === _activePlan.idPlan);
            setCurrentPlan({ ..._activePlan, ...currentPlan, planUpgrade } ?? null);
            setViewScreenPlan(ViewScreen.selectedPlan);
            setTrialStatusPlan(Trial.started);
            setLoading(false);
            return;
        }

        setLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    const closed = (e?) => {
        document.body.classList.remove('bodyOverflowHidden');

        if (viewScreenPlan === ViewScreen.terms || viewScreenPlan === ViewScreen.form) {
            setViewScreenPlan(ViewScreen.upgrade);
            return;
        }

        modal?.remove();
    };

    return (
        <>
            {modal && (
                <button className="closeBtn" onClick={closed}>
                    <IoMdClose />
                </button>
            )}
            <div className={`pctAdminUpgrade ${viewScreenPlan}`}>
                {loading ? (
                    <div className="loader">
                        <div className="preloaderIt"></div>
                    </div>
                ) : (
                    {
                        [ViewScreen.upgrade]: <RequestUpgrade />,
                        [ViewScreen.terms]: <TermUpgrade />,
                        [ViewScreen.form]: <FormUpgrade />,
                        [ViewScreen.selectedPlan]: <SelectedPlanUpgrade />,
                        [ViewScreen.calculatorPlan]: <CalculatorPlanUpgrade />,
                        [ViewScreen.detail]: <DetailPlanUpgrade updatePlan={updatePlan} planUpgrade={planUpgrade} />,
                        [ViewScreen.dialog]: <DialogUpgrade />,
                    }[viewScreenPlan]
                )}
            </div>
        </>
    );
};
const mapDispatchToProps = (dispatch) => ({
    updatePlan: (item) => dispatch(updatePlan(item)),
});

const mapStateToProps = (store) => ({
    planUpgrade: store.planUpgrade,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUpgrade);
