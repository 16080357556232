import React from "react";
import { withRouter } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cabecao from "../assets/images/Cabeção-livro-01.png";
import MapaIcon from "../assets/images/compass.png";
import Gurus from "../assets/images/gurus.png";
import Lives from "../assets/images/lives.png";
import Nerd from "../assets/images/nerd.png";
import Perfil from "../assets/images/perfil.png";
import Podcast from "../assets/images/podcast.png";
import Maratonas from "../assets/images/popcorn.png";
import SmartphoneIcon from "../assets/images/smartphone.svg";
import Spoiler from "../assets/images/spoiler.png";
import Store from "../assets/images/store.png";
import Usuarios from "../assets/images/usuarios.png";
import Valentina from "../assets/images/valentina.png";
import {
  getMenuData,
  getToken,
  getUserIsInCampaign,
  getHasEnrollmentRequests,
} from "../services/api";
import { Store as ReduxStore } from "../store";
import moment from 'moment';

export function hasFooter() {
  const state = ReduxStore.getState();
  const footerStatus = state.footerState.footerStatus;
  const headerStatus = state.footerState.headerStatus;

  if (footerStatus == "true" && headerStatus == "true") {
    return true;
  } else {
    return false;
  }
}

export function getFooterSize() {
  const state = ReduxStore.getState();
  const footerStatus = state.footerState.footerStatus;
  const headerStatus = state.footerState.headerStatus;

  if (footerStatus == "true" && headerStatus == "true") {
    return { paddingBottom: "5rem" };
  } else {
    return {};
  }
}

export function getIcon(name) {
  switch (name) {
    case "Início":
      return SmartphoneIcon;
    case "Mapa da Mina":
      return MapaIcon;
    case "Nerd":
      return Nerd;
    case "Spoiler":
      return Spoiler;
    case "Maratonas":
      return Maratonas;
    case "Gurus":
      return Gurus;
    case "Arya":
      return Valentina;
    case "Cabeção":
      return Cabecao;
    case "Podcasts":
      return Podcast;
    case "Live":
      return Lives;
    case "Usuários":
      return Usuarios;
    case "Store":
      return Store;
    case "Perfil":
      return Perfil;
    default:
      return SmartphoneIcon;
  }
}

export function getAppId() {
  try {
    let token = getProfileInfo();
    let tokenId = token.idApp || 1;
    const digitos = 7;
    let len = tokenId.toString().length;
    let zeros = "0".repeat(digitos - len);

    return `app${zeros}${tokenId}`;
  } catch {
    return "app0000001";
  }
}

export function getRoute(id) {
  switch (id) {
    case 1:
      return "/";
    case 2:
      return "/maratonas";
    case 3:
      return "/gurus";
    case 4:
      return "/arya";
    case 5:
      return "/mapa-da-mina";
    case 6:
      return "/nerd";
    case 7:
      return "/spoiler";
    case 8:
      return "/cabecao";
    case 9:
      return "/podcast";
    case 10:
      return "/live";
    case 11:
      return "/usuarios";
    case 12:
      return "/store";
    case 13:
      return "/perfil";
    case 14:
      return "/ytplaylist";
    default:
      return "/";
  }
}

export function getContentTypeLabel(idItem) {
  const typeContent = {
    1: "Áudio",
    2: "Vídeo",
    3: "Post",
    4: "Conteúdo", ///SITE
    5: "Artigo",
    6: "Schedule",
    7: "Youtube",
    8: "Meeting",
    9: "Livre",
    18: "Arquivo",
    26: "Conteúdo", ///scorm
  };

  return typeContent[idItem] ?? null;
}

export function sanitizeText(phrase, limit) {
  const ret =
    phrase.length > limit ? phrase.substring(0, limit) + "..." : phrase;
  return ret;
}

export function convertToBrDate(dataInput) {
  if (dataInput === null) return "-";

  let data = new Date(dataInput);
  let formatedDate = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  return formatedDate;
}

export function convertToBrDateWithHour(dataInput) {
  if (dataInput === null) return "-";

  let data = new Date(dataInput);
  let formatedDate = `${data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  })} às ${data.getHours() >= 10 ? data.getHours() : "0" + data.getHours()}:${
    data.getMinutes() >= 10 ? data.getMinutes() : "0" + data.getMinutes()
  }h`;
  return formatedDate;
}

export function convertToBrDateWithHourStartAndHourFinish(
  dataInput,
  dataInputFinish
) {
  if (dataInput === null) return "-";

  let data = new Date(dataInput);
  let dateEnd = new Date(dataInputFinish);

  let formatedDate = `${data.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  })} das ${data.getHours() >= 10 ? data.getHours() : "0" + data.getHours()}:${
    data.getMinutes() >= 10 ? data.getMinutes() : "0" + data.getMinutes()
  }h às ${
    dateEnd.getHours() >= 10 ? dateEnd.getHours() : "0" + dateEnd.getHours()
  }:${
    dateEnd.getMinutes() >= 10
      ? dateEnd.getMinutes()
      : "0" + dateEnd.getMinutes()
  }h`;
  return formatedDate;
}

export function getSectionById(id) {
  let menuData = JSON.parse(localStorage.getItem("menu-data"));

  // return empty object if theres no data
  if (!menuData) {
    return { menuOption: [] };
  }

  let section = menuData.menu.filter((obj) => {
    return obj.idSection === parseInt(id);
  })[0];

  // let section = menuData.menu[0]

  // return empty object if theres no data on section
  if (!section) {
    return { menuOption: [] };
  }

  return section;
}

export function pctSaveObj(property, value) {
  let pctObject = {};
  let pctSavedObject = localStorage.getItem("pctObject");

  if (pctSavedObject) {
    pctObject = JSON.parse(pctSavedObject);
  }

  if (value != undefined) {
    pctObject[property] = value;
    localStorage.setItem("pctObject", JSON.stringify(pctObject));
  }

  return pctObject[property];
}

export function getColors(type) {
  let design = getDesignBySectionType(1);
  if (design) {
    switch (type) {
      case "image":
        if (design.backgroundImage) {
          return design.backgroundImage;
        } else {
          return {
            image:
              "https://blast.etc.br/common/app0000001/section/backGroudBlast.jpg",
            showImage: true,
          };
        }
      case "footer":
        if (design.footer) {
          return design.footer;
        } else {
          return {
            color: "#933939",
            showfooter: false,
          };
        }

      case "header":
        if (design.header) {
          return design.header;
        } else {
          return {
            color: "#861414",
          };
        }

      case "home":
        if (design.home) {
          return design.home;
        } else {
          return { homeType: null };
        }

      case "statusBar":
        if (design.statusBar) {
          return design.statusBar;
        } else {
          return {
            color: "#000",
          };
        }

      case "menu":
        if (design.menu) {
          return design.menu;
        } else {
          // return default bedigital colors in case none is set
          return {
            firstColor: "#620606",
            fourthColor: "#f2f2f2",
            secondColor: "#861414",
            thirdColor: "#861414",
          };
        }

      case "menuItem":
        if (design.menuItem) {
          return design.menuItem;
        } else {
          // return default bedigital colors in case none is set
          return {};
        }

      default:
        return design;
    }
  } else {
    return {};
  }
}

export function getSectionByType(type) {
  let menuData = JSON.parse(localStorage.getItem("menu-data"));

  // return empty object if theres no data
  if (!menuData) {
    return { menuOption: [] };
  }
  if (!menuData.menu) {
    return { menuOption: [] };
  }

  let section = menuData.menu.filter((obj) => {
    return obj.idSectionType === parseInt(type);
  })[0];

  // let section = menuData.menu[0]

  // return empty object if theres no data on section
  if (!section) {
    return { menuOption: [] };
  }

  return section;
}

export function getProfileInfo(urlToken) {
  let token = {};
  try {
    token = jwtDecode(urlToken || getToken());
  } catch {
    console.log("error on token get");
  }
  //   console.log(token);
  return token;
}

export function getPoints() {
  /*let token = {}
    try {
        token = jwtDecode(getToken())
    } catch {
        console.log('error on token get')
    }*/
  return 100;
}

export function getGamigication() {
  return getMenuData()?.loginInfo?.gamification === 1;
}

export function getAppDetails() {
  const appDetails = JSON.parse(localStorage.getItem("appDetails"));
  return appDetails;
}

export function getAppType() {
  const appDetails = JSON.parse(localStorage.getItem("appDetails"));
  return appDetails?.idAppType;
}

export function getAllDesign() {
  let menuData = JSON.parse(localStorage.getItem("menu-data"));
  return menuData.design;
}

export function getDesignBySectionType(idType) {
  let menuData = JSON.parse(localStorage.getItem("menu-data"));

  // return empty object if theres no data
  if (!menuData) {
    return {};
  }
  if (!menuData.design) {
    return {};
  }

  let section = menuData.design.filter((obj) => {
    return obj.idSectionType === parseInt(idType);
  })[0];

  // return empty object if theres no data on section
  if (!section) {
    return {};
  }

  return section.format;
}

export function getDesignById(id) {
  let menuData = JSON.parse(localStorage.getItem("menu-data"));

  // return empty object if theres no data
  if (!menuData) {
    return {};
  }
  if (!menuData.design) {
    return {};
  }

  let section = menuData.design.filter((obj) => {
    return obj.idSection === parseInt(id);
  })[0];

  // return empty object if theres no data on section
  if (!section) {
    return {};
  }

  return section.format;
}

export function transformText(text) {
  return text
    ?.toLowerCase()
    .replace(/ /g, "-")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function getPathBySectionId(id) {
  const menus = getAllMenuOptions();

  const sectionName = menus?.find((e) => e.sectionTo === parseInt(id))?.text;
  if (!sectionName) return null;
  return `/${transformText(sectionName)}/${id}` || null;
}

export function getAllMenuOptions() {
  let allMenu = getMenuData();
  if (allMenu === null || typeof allMenu === "undefined") {
    return [];
  }
  if (!allMenu.menu) {
    return [];
  }
  allMenu = allMenu.menu;
  let allMenuOptions = [];

  allMenu.forEach((element) => {
    element.menuOption.forEach((elem) => {
      allMenuOptions.push({ ...elem, parentIdSection: element.idSection });
    });
  });
  return allMenuOptions;
}

export function getDesign(idSection, item, only = false, valueOnly = false) {
  let design = getDesignById(idSection);

  if (!design[item]) {
    return null;
  } // return null if theres no item specified

  if (only) {
    // parameter only means it will only return the property specified
    if (valueOnly) {
      return design[item][only];
    } // parameter valueOnly means it will only return the property value if specified

    design = { [only]: design[item][only] };
    return design;
  }

  return design[item];
}

export function validImage(image) {
  return image && image !== "null";
}

export const SCORM_CDN_TEST = {
  COOKIE_NAME: "cdntst",
  PASSED: "passed",
  FAILED: "failed",
};

export const getEnrollmentCampaignStatus = async () => {
  const idUser = getProfileInfo().idUser;

  return await Promise.all([
    getUserIsInCampaign(),
    getHasEnrollmentRequests(idUser),
  ]);
};

export const removeTagsHtml = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  return str.replace(/(<([^>]+)>)/gi, "");
};

export const withRouterAndRef = (Wrapped) => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
    <Wrapped ref={forwardRef} {...otherProps} />
  ));
  const WithRouterAndRef = React.forwardRef((props, ref) => (
    <WithRouter {...props} forwardRef={ref} />
  ));
  const name = Wrapped.displayName || Wrapped.name;
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`;
  return WithRouterAndRef;
};

export const compareTwoArrays = (
  arr1,
  arr2,
  arr1PROP = "id",
  arr2PROP = "id"
) => {
  arr1.map((it) => {
    it.idData = it[arr1PROP];
    return it;
  });

  arr2.map((it) => {
    it.idData = it[arr2PROP];
    return it;
  });

  // Extrair os IDs dos elementos do segundo array
  const arr2Ids = arr2.map((item) => item.idData);

  // Filtrar os IDs que não estão presentes no primeiro array
  const differentIds = arr2Ids.filter(
    (idData) => !arr1.some((item) => item.idData === idData)
  );

  // Retornar os elementos correspondentes aos IDs diferentes
  return arr2.filter((item) => differentIds.includes(item.idData));
};

export const formatValueReal = (valor) => {
  return valor.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const  formatValueToReal = (value) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
};

export const formatDate = (date) =>{
  const now = moment();
  const diffSeconds = now.diff(date, 'seconds');
  const diffMinutes = now.diff(date, 'minutes');
  const diffHours = now.diff(date, 'hours');
  const diffDays = now.diff(date, 'days');
  const diffMonths = now.diff(date, 'months');
  const diffYears = now.diff(date, 'years');

  if (diffSeconds < 60) {
    return `${diffSeconds}s`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes}m`;
  } else if (diffHours < 24) {
    return `${diffHours}h`;
  } else if (diffDays < 30) {
    return `${diffDays}d`;
  } else if (diffMonths < 12) {
    return `${diffMonths}m`;
  } else {
    return `${diffYears}a`;
  }
}